import React, { useState, useEffect } from "react";
import Table from "react-bootstrap/Table";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import Badge from "react-bootstrap/Badge";
import axios from "axios";
import { Spinner, Row, Col } from "react-bootstrap";
import AddEditPostForm from "./AddEditPostForm";
import Swal from "sweetalert2";
import dateFormat from "dateformat";

function PostTable(props) {
  const [selectedPost, setSelectedPost] = useState();
  const [editModal, setEditModal] = useState(false);
 const {loaded, posts, loadPosts} = props
  const handleEdit = (p) => {
    setSelectedPost(p);
    setEditModal(true);
  };
  const handleDelete = () => {
      axios.delete(`/api/post/${selectedPost.post_id}`).then(res => {
          Swal.fire({title: 'Post Deleted.', timer: 1000, position: 'top-end', showConfirmButton: false})
          setEditModal(false)
          loadPosts()
      }).catch(err => {
          Swal.fire({title: "Error", text: `Message: ${err}`, })
      })
  }
  return loaded ? (
    <Table striped hover bordered responsive className="">
      <thead>
      <tr>
        <th>#</th>
        <th>Name</th>
        <th>Title</th>

        <th className="">Event Date</th>
        <th className="">Link</th>

        <th className="">Edit</th>
      </tr>
      </thead>
      <tbody>
        {posts &&
          posts.sort((a,b) => b.post_id - a.post_id).map((p) => {
            return (
              <tr key={p.post_id}>
                <td>{p.post_id}</td>
                <td>
                  <a href={`/politician/${p.politician_id}`}>
                    {p.fn + " " + p.ln}
                  </a>
                </td>
                <td>{p.title}</td>
                <td>{dateFormat(p.event_date, "UTC:mm/dd/yyyy")}</td>

                <td>
                  <a href={p.link}>Link</a>
                </td>

                <td>
                  <Badge pill onClick={() => handleEdit(p)} className=" btn">
                    Edit
                  </Badge>
                  
                </td>
              </tr>
            );
          })}
      </tbody>
      <Modal
        show={editModal}
        onHide={() => setEditModal(false)}
        size="lg"
        centered
      >
        <Modal.Header closeButton>
          <h4>Edit Post</h4>
        </Modal.Header>
        <Modal.Body>
          <AddEditPostForm
            loadPosts={loadPosts}
            setEditModal={setEditModal}
            thisPost={selectedPost}
            editMode={true}
          />
        </Modal.Body>
        <Modal.Footer className='d-flex justify-content-between'>
        <Button variant="secondary" onClick={() => handleDelete()}>
            Delete
          </Button>
          <Button variant="danger" onClick={() => setEditModal(false)}>
            Cancel
          </Button>
        </Modal.Footer>
      </Modal>
    </Table>
  ) : (
    <Row className="d-flex justify-content-center">
      <Spinner animation="grow" />
    </Row>
  );
}

export default PostTable;

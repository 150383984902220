import React, { useState, useEffect } from "react";
import "./EndorsementManager.scss";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import axios from "axios";
import Button from "react-bootstrap/Button";
import Spinner from "react-bootstrap/Spinner";
import Container from "react-bootstrap/Container";
import Swal from "sweetalert2";
import dateFormat from "dateformat";
import Endorsement from "../../../Endorsements/Endorsement";
import Upload from "../../../Uploads/Upload";
function AddEditEndorsement(props) {
  const [polData, setPolData] = useState();
  const [loaded, setLoaded] = useState(false);
  const [fileUrl, setFileUrl] = useState([{ name: "", url: "" }]);
  const [fileNameRed, setFileNameRed] = useState(true)
  const [state, setState] = useState({
    body_text: "",
    link: "",
    expiration: "",
    position: "",
    politician_id: 0,
    active: true,
    file_name: "",
  });
  const { thisEnd, editMode, setEditModal, loadEndorsements } = props;
  const [selectedPol, setSelectedPol] = useState();
  useEffect(() => {
    loadData();
  }, []);
  const loadData = () => {
    axios.get("/api/admin/politician").then((res) => {
      setPolData(res.data);
      setLoaded(true);
    });
  };

  console.log("file url", fileUrl);
  useEffect(() => {
    console.log('1')
    if (editMode && thisEnd.fn && polData) {
      let thisExp = dateFormat(thisEnd.expiration, "isoDate");
      setState({
        e_id: thisEnd.e_id,
        body_text: thisEnd.body_text,
        link: thisEnd.link,
        expiration: thisExp,
        position: thisEnd.position,
        politician_id: thisEnd.politician_id,
        active: thisEnd.active,
        file_name: thisEnd.file_name || '',
        
      });
      setFileUrl([{name: thisEnd.file_name || '', url: thisEnd.file_url || ''}])
      let index = polData.politicians.findIndex((p) => {
        return p.politician_id === thisEnd.politician_id;
      });
      setSelectedPol(polData.politicians[index]);
    }
  }, [loaded]);
  useEffect(() => {
    console.log("new file name check");
    if (fileUrl[0].name) {
      let trimmed = fileUrl[0].name.replace(/\.[^/.]+$/, "");
      setState({ ...state, file_name: trimmed });
      setFileNameRed(true)
    }
  }, [fileUrl, fileUrl[0]]);
  console.log("state", state);
  const handleSubmit = (e) => {
    e.preventDefault();
    let body = { ...state, file_url: fileUrl[0].url };
    if (editMode) {
      axios.put("/api/admin/endorsement", body).then((res) => {
        setState({
          body_text: "",
          link: "",
          expiration: "",
          position: "",
          politician_id: 0,
          file_name: '',
          
        });
        setFileNameRed(true)
        setFileUrl([{name: '', url: ''}])
        Swal.fire({
          position: "top-center",
          icon: "success",
          title: "Endorsement updated successfully.",
          showConfirmButton: false,
          timer: 1500,
        });
        setEditModal(false);
        loadEndorsements();
      });
    } else {
      axios
        .post("/api/admin/endorsement", body)
        .then((res) => {
          setState({
            body_text: "",
            link: "",
            expiration: "",
            position: "",
            politician_id: 0,
            file_name: '',
            
          });
          setFileNameRed(true)
          setFileUrl([{name: '', url: ''}])
          Swal.fire({
            icon: "success",
            title: "Endorsement created successfully.",
            showConfirmButton: false,
            timer: 1500,
          });
          setSelectedPol();
          loadEndorsements();
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };
  const handleInput = (e) => {
    if (e.target.name === "politician_id") {
      let index = polData.politicians.findIndex((p) => {
        return p.politician_id === +e.target.value;
      });
      setSelectedPol(polData.politicians[index]);
    }
    setState({ ...state, [e.target.name]: e.target.value });
  };

  return loaded ? (
    <>
      <Form onSubmit={handleSubmit} className="bg-success p-3 text-light">
        <h2>Add an Endorsement </h2>
        <p>
          <em>
            If the politician does not show up in the list, you must add them
            first in the politicians tab.
          </em>
        </p>
        <Row>
          <Form.Group as={Col} xs="12" md="6">
            <Form.Label>Position</Form.Label>
            <Form.Control
              type="text"
              name="position"
              value={state.position}
              onChange={handleInput}
              required
            />
          </Form.Group>
          <Form.Group as={Col} xs="12" md="6">
            <Form.Label>Politician</Form.Label>
            <Form.Select
              name="politician_id"
              value={state.politician_id}
              onChange={handleInput}
              required
            >
              <option value="">--</option>
              {polData &&
                polData.politicians
                  .sort((a, b) => a.fn.localeCompare(b.fn))
                  .map((p) => {
                    return (
                      <option key={p.politician_id} value={p.politician_id}>
                        {p.fn + " " + p.ln}
                      </option>
                    );
                  })}
            </Form.Select>
          </Form.Group>
        </Row>
        {/* <Form.Group >
          <Form.Label>Title</Form.Label>
          <Form.Control
            name="title"
            onChange={handleInput}
            type="text"
            value={state.title}
            placeholder="Title for your post."
            required
          />
        </Form.Group> */}
        <Form.Group className="mt-2">
          <Form.Label>Description</Form.Label>
          <Form.Control
            as="textarea"
            name="body_text"
            value={state.body_text}
            onChange={handleInput}
            placeholder="Body text of your post"
            required
          />
        </Form.Group>
        <Form.Group className="mt-2">
          <Form.Label>Link - Must start with http:// or https://</Form.Label>
          <Form.Control
            type="url"
            name="link"
            value={state.link}
            onChange={handleInput}
            placeholder="Must start with http:// or https://"
          />
        </Form.Group>
        <div
          className={
            fileUrl[0].url.length > 0
              ? "border border-light rounded-3 p-3 my-2"
              : ""
          }
        >
          <Form.Group className="mt-2">
            <Form.Label>You can upload a file for people to view with this endorsement (preview below).</Form.Label>
            <Upload setUrl={setFileUrl} urls={[]} />
            {fileUrl[0].url.length > 0 && (

              <a className='text-info' href={fileUrl[0].url} target="_blank" rel="noreferrer">View {state.file_name} in a new tab.</a>
            )}
          </Form.Group>
          {fileUrl[0].url.length > 0 && (
            <Form.Group className="my-2">
              <Form.Label>
                Create a <b>descriptive</b> title for this file. This will show when they click on it.
              </Form.Label>

              <Form.Control
                type="text"
                onFocus={() => setFileNameRed(false)}
                name="file_name"
                value={state.file_name}
                className={fileNameRed && 'border-danger border-3'}
                onChange={handleInput}
                placeholder="Election Data Example Title"
              />
            </Form.Group>
          )}
        </div>

        <Row>
          <Form.Group className="mt-2">
            <Form.Label>Expires: </Form.Label>
            <p>
              <em className="text-info">Enter the date of the election.</em>
            </p>
            <Form.Control
              name="expiration"
              onChange={handleInput}
              type="date"
              value={state.expiration}
              placeholder="Title for your post."
              required
            />
          </Form.Group>
        </Row>
        <Row>
          <Col>
            <Form.Group className="mt-3">
              <Form.Label>Active on Endorsements Page</Form.Label>
              <Form.Check
                onClick={() => setState({ ...state, active: !state.active })}
                type="switch"
                id="active-switch"
                checked={state.active}
              />
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col className="d-flex justify-content-end">
            <Button className="bg-light text-dark my-3 " type="submit">
              {props.editMode ? "Submit Changes" : "Create Endorsement"}
            </Button>
          </Col>
        </Row>

        <Row>
          <Col className="rounded-3 bg-light">
            <Container>
              {selectedPol ? (
                <Endorsement
                  endorsement={{
                    ...state,
                    fn: selectedPol.fn,
                    ln: selectedPol.ln,
                    file_url: fileUrl[0].url,
                  }}
                />
              ) : (
                <p className="text-dark p-0 m-0">
                  Select a politician to generate a preview.
                </p>
              )}
            </Container>
          </Col>
        </Row>
      </Form>
    </>
  ) : (
    <Row>
      <Spinner animation="grow" variant="success" className="mx-auto" />
    </Row>
  );
}

export default AddEditEndorsement;

import React, { useState, useEffect } from "react";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import Spinner from "react-bootstrap/Spinner";
import Post from "../../../Posts/Post";
import "./PostManager.scss";
import axios from "axios";
import Swal from "sweetalert2";
import dateFormat from "dateformat";
import Upload from "../../../Uploads/Upload";
import emailjs from "@emailjs/browser";

const { REACT_APP_SERVICE_ID, REACT_APP_POST_TEMPLATE_ID, REACT_APP_USER_ID } =
  process.env;
function AddEditPostForm(props) {
  const { editMode, thisPost } = props;
  const [polData, setPolData] = useState();
  const [fileUrl, setFileUrl] = useState([{ name: "", url: "" }]);
  const [fileNameRed, setFileNameRed] = useState(true);
  const [loaded, setLoaded] = useState(false);
  const [state, setState] = useState({
    title: "",
    body_text: "",
    link: "",
    post_img: "",
    event_date: "",
    politician_id: 0,
    wall: "",
    file_name: "",
  });
  const [selectedPol, setSelectedPol] = useState();

  useEffect(() => {
    if (!props.memberMode) {
      loadData();
    } else {
      setPolData(props.info);
      setLoaded(true);
    }
  }, [props.info]);
  useEffect(() => {
    if (editMode && thisPost.fn && polData) {
      setState({
        ...state,
        post_id: thisPost.post_id,
        title: thisPost.title,
        body_text: thisPost.body_text,
        link: thisPost.link,
        event_date: dateFormat(thisPost.event_date, "isoDate"),
        post_img: thisPost.post_img,
        politician_id: thisPost.politician_id,
        wall: thisPost.wall_type,
        file_name: thisPost.file_name || "",
      });
      setFileUrl([
        { name: thisPost.file_name || "", url: thisPost.file_url || "" },
      ]);
      let index = polData.politicians.findIndex((p) => {
        return p.politician_id === thisPost.politician_id;
      });
      setSelectedPol(polData.politicians[index]);
    }
  }, [loaded]);
  const loadData = () => {
    setLoaded(false);
    axios.get("/api/admin/politician").then((res) => {
      setPolData(res.data);
      setLoaded(true);
    });
  };
  useEffect(() => {
    console.log("new file name check");
    if (fileUrl[0].name) {
      let trimmed = fileUrl[0].name.replace(/\.[^/.]+$/, "");
      setState({ ...state, file_name: trimmed });
      setFileNameRed(true);
    }
  }, [fileUrl, fileUrl[0]]);
  const handleSubmit = (e) => {
    e.preventDefault();
    let body = { ...state, file_url: fileUrl[0].url };

    if (editMode) {
      axios.put("/api/post", body).then((res) => {
        setState({
          title: "",
          body_text: "",
          link: "",
          event_date: "",
          post_img: "",
          politician_id: 0,
          wall: "",
          file_name: "",
        });
        setFileNameRed(true);
        setFileUrl([{ name: "", url: "" }]);
        Swal.fire({
          position: "top-center",
          icon: "success",
          title: "Post updated successfully.",
          showConfirmButton: false,
          timer: 1500,
        });
        if (!props.memberMode) {
          props.loadPosts();

          props.setEditModal(false);
        }
        setSelectedPol();
      });
    } else {
      axios.post("/api/admin/post", body).then((res) => {
        if (props.memberMode) {
          emailjs.send(
            REACT_APP_SERVICE_ID,
            REACT_APP_POST_TEMPLATE_ID,
            {
              display_name: props.user?.display_name || "'ERROR getting name'",
              post_title: state.title,
              post_body: state.body_text,
              wall: state.wall === "fame" ? "Fame" : "Shame",
              link: state.link,
              politician: selectedPol.fn + " " + selectedPol.ln,
            },
            REACT_APP_USER_ID
          );
        }
        setState({
          title: "",
          body_text: "",
          link: "",
          event_date: "",
          post_img: "",
          politician_id: 0,
          wall: "",
          file_name: "",
        });
        setFileNameRed(true);
        setFileUrl([{ name: "", url: "" }]);
        Swal.fire({
          position: "top-center",
          icon: "success",
          title: "Post created successfully.",
          showConfirmButton: false,
          timer: 1500,
        });
        setSelectedPol();

        if (!props.memberMode) {
          props.loadPosts();
          if (props.setEditModal) {
            props.setEditModal(false);
          }
        }
      });
    }
  };
  const inputHandler = (e) => {
    if (e.target.name === "politician_id") {
      let index = polData.politicians.findIndex((p) => {
        return p.politician_id === +e.target.value;
      });
      setSelectedPol(polData.politicians[index]);
    }
    setState({ ...state, [e.target.name]: e.target.value });
  };
  console.log("state", state);
  return loaded && polData ? (
    <>
      <Form
        onSubmit={handleSubmit}
        className="add-edit-post-form p-3 text-light"
      >
        {!editMode ? <h2>Add a Post</h2> : <h2>Edit Post</h2>}
        <Form.Group>
          <Form.Label>Title</Form.Label>
          <Form.Control
            name="title"
            onChange={inputHandler}
            type="text"
            value={state.title}
            placeholder="Title for your post."
            required
          />
        </Form.Group>
        <Form.Group>
          <Form.Label>Description</Form.Label>
          <Form.Control
            as="textarea"
            name="body_text"
            value={state.body_text}
            onChange={inputHandler}
            placeholder="Body text of your post"
            required
          />
        </Form.Group>
        <Form.Group>
          <Form.Label>Link</Form.Label>
          <Form.Control
            type="url"
            name="link"
            value={state.link}
            onChange={inputHandler}
            placeholder="Must start with http// or https://"
          />
        </Form.Group>
        <Form.Group>
          <Form.Label>Date</Form.Label>
          <Form.Control
            required
            type="date"
            name="event_date"
            value={state.event_date}
            onChange={inputHandler}
          />
        </Form.Group>
        <Form.Group>
          <Form.Label>Alternative Image URL</Form.Label>
          <Form.Control
            type="url"
            name="post_img"
            value={state.post_img}
            onChange={inputHandler}
            placeholder="Link to image that will replace the image of the politician."
          />
        </Form.Group>
        <div
          className={
            fileUrl[0].url.length > 0
              ? "border border-light rounded-3 p-3 my-2"
              : ""
          }
        >
          <Form.Group className="mt-2">
            <Form.Label>
              You can upload a file for people to view with this post (preview
              below). This is for documents (pdf, word) or photos that aid in
              the reader's understanding.
              <em className="text-dark">
                {" "}
                Do not upload a picture of the politician.
              </em>
            </Form.Label>
            <Upload setUrl={setFileUrl} urls={[]} />
            {fileUrl[0].url.length > 0 && (
              <a
                className="text-dark"
                href={fileUrl[0].url}
                target="_blank"
                rel="noreferrer"
              >
                <b>View {state.file_name} in a new tab.</b>
              </a>
            )}
          </Form.Group>
          {fileUrl[0].url.length > 0 && (
            <Form.Group className="my-2">
              <Form.Label>
                Create a <b>descriptive</b> title for this file. This will show
                when they click on it.
              </Form.Label>

              <Form.Control
                type="text"
                onFocus={() => setFileNameRed(false)}
                name="file_name"
                value={state.file_name}
                className={fileNameRed && "border-danger border-3"}
                onChange={inputHandler}
                placeholder="Election Data Example Title"
              />
            </Form.Group>
          )}
        </div>
        <Row>
          <Form.Group as={Col} xs="12" md="6">
            <Form.Label>Politician</Form.Label>
            <Form.Select
              name="politician_id"
              value={state.politician_id}
              onChange={inputHandler}
              required
            >
              <option value="">--</option>
              {polData &&
                polData.politicians
                  .sort((a, b) => a.fn.localeCompare(b.fn))
                  .map((p) => {
                    return (
                      <option key={p.politician_id} value={p.politician_id}>
                        {p.fn + " " + p.ln}
                      </option>
                    );
                  })}
            </Form.Select>
          </Form.Group>
          <Form.Group as={Col} xs="12" md="6">
            <Form.Label>Wall</Form.Label>
            <Form.Select
              required
              name="wall"
              value={state.wall}
              onChange={inputHandler}
            >
              <option value="">--</option>
              <option value="fame" className="text-primary">
                Wall of Fame
              </option>
              <option value="shame" className="text-danger">
                Wall of Shame
              </option>
            </Form.Select>
          </Form.Group>
        </Row>
        <p>
          <em>
            If the politician isn't listed, add them using the "Add Politician"
            form first.
          </em>
        </p>
        <Row>
          <Col className="d-flex justify-content-end">
            <Button className="bg-light text-primary my-3 " type="submit">
              {props.editMode ? "Submit Changes" : "Create Post"}
            </Button>
          </Col>
        </Row>
      </Form>
      {state.title.length > 0 ? (
        <>
          <h3 className="text-center my-2">Post Preview</h3>
          <Post post={{ ...state, ...selectedPol, file_url: fileUrl[0].url }} />
        </>
      ) : null}
    </>
  ) : (
    <Row className="d-flex justify-content-center">
      <Spinner animation="grow" variant="primary" className="mx-auto" />
    </Row>
  );
}

export default AddEditPostForm;

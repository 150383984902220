import React,{ useState} from 'react'
import Button from "react-bootstrap/Button";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Alert from 'react-bootstrap/Alert';
import axios from 'axios';
function AddPosition() {
  const [err, setErr] = useState(false)
  const [show, setShow] = useState(false)
  const [validated, setValidated ] = useState(false)
    const [state, setState] = useState({
        position: ''
    })
    const handleSubmit = (e) => {
        e.preventDefault()
        let form = e.currentTarget
        if(form.checkValidity() === false){
          e.stopPropagation()
          setValidated(true)
        } else {
          axios.post('/api/admin/position', state).then(
            () => {
              setErr(false)
              setShow(true)
              setState({position: ''})
            }
            ).catch(err => {
              setErr(true)
              setShow(true)
              console.log(err)
            })
          }
    }
    return (
        <Form onSubmit={handleSubmit} noValidate validated={validated} className="bg-secondary p-3 mt-3 text-light">
      <Row>
        <h2>New Position</h2>
      </Row>
      <Row>
      <Form.Group as={Col}>
        <Form.Label>Position Name</Form.Label>
        <Form.Control required value={state.position} onChange={(e) => setState({position: e.target.value})} type="text" placeholder="Governor" />
      </Form.Group>
      <Form.Group className='d-flex justify-content-end'>
        <Button type="submit" className='mt-2'>Add</Button>
      </Form.Group>
      </Row>
      <Row>
      <Col className='my-2'>
          {show ? (
            err ? (
              <Alert
                dismissible="true"
                variant="danger"
                onClose={() => setShow(false)}
              >
                Failed.
              </Alert>
            ) : (
              <Alert
                dismissible="true"
                variant="success"
                onClose={() => setShow(false)}
              >
                Success.
              </Alert>
            )
          ) : null}
        </Col>
      </Row>
    </Form>
    )
}

export default AddPosition

import React from "react";
import "./Contact.scss";
import Container from "react-bootstrap/Container";
import ContactForm from "./ContactForm/ContactForm";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
function Contact() {
  return (
    <Container
      id="contact-page-container"
      className=" d-flex justify-content-center align-items-center"
    >
      <Row className="contact-container py-5 ">
        <Col
          xs="12"
          md="6"
          className="d-flex flex-column justify-content-start "
        >
          <Row className="my-3 mx-1">
            <Col>
              <h3>Contact Us</h3>
            </Col>
          </Row>
          <Row className="my-3 mx-1">
            <Col>
              <h6>Fill out the form and our team will get back promptly.</h6>
            </Col>
          </Row>
          {/* <Row className="mx-1">
            <Col className="d-flex contact-info">
              <i className="bi bi-telephone"></i>
              <p className="ms-2">800 900 1111</p>
            </Col>
          </Row> */}
          <Row className="mx-1">
            <Col className="d-flex align-items-center contact-info">
              <i className="bi bi-envelope"></i>
              <p className="ms-2">contact@utahconservatives.org</p>
            </Col>
          </Row>
          {/* <Row className="mx-1">
            <Col className="d-flex align-items-center contact-info mb-2">
              <i className="bi bi-geo-alt"></i>
              <p className="ms-2">1234 Main St, Lehi, UT</p>
            </Col>
          </Row> */}
          {/* <Row className="mx-1">
            <Col xs="12" className="my-4">
              <i className="bi bi-facebook contact-info mx-1"></i>
              <i className="bi bi-twitter contact-info mx-2"></i>
              <i className="bi bi-linkedin contact-info mx-2"></i>
            </Col>
          </Row> */}
        </Col>
        <Col xs="12" md="6">
          <ContactForm />
        </Col>
      </Row>
    </Container>
  );
}

export default Contact;

import React, { useState } from "react";
import Container from "react-bootstrap/Container";
import Form from "react-bootstrap/Form";
import FloatingLabel from "react-bootstrap/FloatingLabel";
import Button from "react-bootstrap/Button";
import "./ContactForm.scss";
import emailjs from "@emailjs/browser";
import Swal from "sweetalert2";

const { REACT_APP_SERVICE_ID, REACT_APP_TEMPLATE_ID, REACT_APP_USER_ID } =
  process.env;

function ContactForm() {
  const [contactInfo, setContactInfo] = useState({
    from_name: "",
    from_email: "",
    message: "",
  });
  const [validated, setValidated] = useState(false);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (contactInfo.disabled) return;
    let form = e.currentTarget;

    if (form.checkValidity() === false) {
      e.stopPropagation();
      setValidated(true);
    } else {
      emailjs
        .send(
          REACT_APP_SERVICE_ID,
          REACT_APP_TEMPLATE_ID,
          contactInfo,
          REACT_APP_USER_ID
        )
        .then(() => {
          setContactInfo({
            from_name: "",
            from_email: "",
            message: "",
            disabled: true,
          });
          Swal.fire({
            icon: "success",
            title: "Message Sent Successfully",
            text: "You should hear back from us as soon as possible.",
            confirmButtonColor: "#013D80",
          });
        })
        .catch((err) => console.log(err));
    }
  };

  return (
    <Container>
      <Form
        onSubmit={handleSubmit}
        noValidate
        validated={validated}
        className="border  contact-form shadow  p-4"
      >
        <FloatingLabel controlId="" label="Name" className="mb-3">
          <Form.Control
            required
            type="text"
            value={contactInfo.from_name}
            placeholder="Name"
            onChange={(e) => {
              setContactInfo({ ...contactInfo, from_name: e.target.value });
            }}
          />
        </FloatingLabel>

        <FloatingLabel
          controlId="floatingInput"
          label="Email address"
          className="mb-3"
        >
          <Form.Control
            required
            type="email"
            value={contactInfo.from_email}
            placeholder="name@example.com"
            label="email"
            onChange={(e) => {
              setContactInfo({ ...contactInfo, from_email: e.target.value });
            }}
          />
        </FloatingLabel>
        <FloatingLabel label="Message" className="mb-3">
          <Form.Control
            required
            as="textarea"
            value={contactInfo.message}
            style={{ height: "100px" }}
            onChange={(e) => {
              setContactInfo({ ...contactInfo, message: e.target.value });
            }}
          />
        </FloatingLabel>
        <Button
          disabled={contactInfo.disabled}
          variant="outline-primary"
          size="lg"
          type="submit"
        >
          SEND
        </Button>
      </Form>
    </Container>
  );
}

export default ContactForm;

import React, { useState } from "react";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import { Link, withRouter } from "react-router-dom";
import axios from "axios";
import Swal from "sweetalert2";
import {
  getAuth,
  signInWithPopup,
  GoogleAuthProvider,
  setPersistence,
  browserLocalPersistence,
  onAuthStateChanged,
  signInWithEmailAndPassword,
  signOut,
  createUserWithEmailAndPassword,
  updateProfile,
} from "firebase/auth";

function SignInForm(props) {
  const { REACT_APP_UPLOAD_PASSWORD } = process.env;
  let auth = getAuth();
  const [state, setState] = useState({
    email: "",
    password: "",
  });
  const handleInput = (e) => {
    setState({ ...state, [e.target.name]: e.target.value });
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    axios
      .post("/api/auth/login", state)
      .then((res) => {
        if(res.data.role){

          firebaseUploadAuth();
          if (res.data.role === "admin") {
            props.history.push("/admin");
          } else {
            props.history.push('/portal')
          } 
          
          props.setSignInModal(false);
        }
      })
      .catch((err) => {
        Swal.fire({
          title: `${err.response.data}`,
          confirmButtonColor: "red",
        });
      });
    const firebaseUploadAuth = () => {
      // e.preventDefault();
      setPersistence(auth, browserLocalPersistence).then(() => {
        signInWithEmailAndPassword(
          auth,
          "uploads@utahconservatives.org",
          REACT_APP_UPLOAD_PASSWORD
        )
          .then(async (result) => {
            console.log("Firebase logged in.");
          })
          .catch((error) => {
            const errorCode = error.code;
            const errorMessage = error.message;
            console.log(errorCode, errorMessage);
          });
      });
    };
  };
  return (
    <Form onSubmit={handleSubmit}>
      <Form.Group>
        <Form.Label>Email</Form.Label>
        <Form.Control
          type="text"
          value={state.email}
          name="email"
          placeholder="example@mail.com"
          onChange={handleInput}
        />
      </Form.Group>
      <Form.Group className="my-2">
        <Form.Label>Password</Form.Label>
        <Form.Control
          type="password"
          onChange={handleInput}
          value={state.password}
          name="password"
        />
      </Form.Group>
      <Button type="submit">Sign In</Button>
      <Link
        to="/signup"
        onClick={() => props.setSignInModal(false)}
        className="d-block mt-2"
      >
        Sign up for an account instead.
      </Link>
    </Form>
  );
}

export default withRouter(SignInForm);

import axios from "axios";
import React, { useState, useEffect } from "react";
import { Row, Container, Spinner } from "react-bootstrap";
import { withRouter } from "react-router";
import ViewPol from "./ViewPol";

function PolPage(props) {
  useEffect(() => {
    getPol();
  }, []);

  const [pol, setPol] = useState();
  const [loaded, setLoaded] = useState(false);
  const getPol = () => {
    setLoaded(false)
    axios
      .get(`/api/pol/${props.match.params.pol_id}`)
      .then((res) => {
        setPol(res.data)
        setLoaded(true)
      });
  };
  return (
    <Container>
        {loaded ? (

            <ViewPol pol={pol} />
            ) : (
                <Row className='d-flex justify-content-center'>
                    <Spinner animation='grow'/>
                </Row>
            )}
    </Container>
  );
}

export default withRouter(PolPage);

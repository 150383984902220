import React, { useEffect, useState } from "react";
import axios from "axios";
import Container from "react-bootstrap/Container";
import Spinner from "react-bootstrap/Spinner";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import Badge from "react-bootstrap/Badge";
import { withRouter } from "react-router";
import { UserInfo } from "firebase-admin/lib/auth/user-record";
import PoliticianManager from "../Admin/Tools/PoliticianManager/PoliticianManager";
import Permissions from "./Permissions";
import UserTasks from "../Admin/Tools/UserManager/UserTasks";
function Portal(props) {
  const [user, setUser] = useState({});
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    checkUser();
  }, []);

  const checkUser = () => {
    const kick = () => {
      axios.post("/api/auth/logout").then(() => console.log("Kicked."));
      props.history.push("/");
    };
    // add pol
    // add posts

    axios
      .get("/api/auth/user")
      .then((res) => {
        if (res.data.user_id) {
          if (res.data.role === "admin") {
            props.history.push("/admin");
            return;
          }
          setUser(res.data);
        } else {
          kick();
        }
        setLoaded(true);
      })
      .catch(() => kick());
  };
  return loaded && user.user_id ? (
    <Container>
      <h2>Welcome, {user.display_name}</h2>
      {user.role === "user" && (
        <>
          <p>Thank you for signing up, someone will reach out to you soon. </p>
          <p>
            You have not been assigned any permissions yet. If you are expecting
            some, please contact an administrator for more permissions.
          </p>
          <p>
            In the future, users will be given certain permissions as the site
            gains more features.
          </p>
        </>
      )}
      {user.role === "member" && <Permissions user={user} />}
      <h3 className='mt-5'>Your Tasks</h3>
      <UserTasks user_id={user?.user_id} />
    </Container>
  ) : (
    <Container className="d-flex justify-content-center">
      <Spinner animation="grow" />
    </Container>
  );
}

export default withRouter(Portal);

import React, { useState } from "react";
import Container from "react-bootstrap/Container";
import './PoliticianManager.scss'
import Button from "react-bootstrap/Button";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import InputGroup from "react-bootstrap/InputGroup";
import Form from "react-bootstrap/Form";
import Alert from "react-bootstrap/Alert";
import axios from "axios";

function AddHeldPosition(props) {
  const [validated, setValidated] = useState(false)
  const [err, setErr] = useState(false)
  const [show, setShow] = useState(false)
  const [state, setState] = useState({
    politician_id: '',
    position: '',
    newPosition: false,
    current: true,
    startDate: '',
    endDate: '',
    datesValid: false
  });
  const inputHandler = (e) => {
    if(e.target.name === 'position' && e.target.value === "Add New"){
      setState({...state, newPosition: true})
    } else {
      setState({...state, [e.target.name]: e.target.value})
    }
  }
  const handleSubmit = (e) => {
    e.preventDefault()
    let form = e.currentTarget
    console.log('state',state)
    if(state.endDate.length > 0 && state.startDate > state.endDate){
      console.log('start date incorrect')
      setState({...state, datesValid: false})
      setValidated(true)
    } else if(form.checkValidity() === false){
      e.stopPropagation()
      setValidated(true)
    } else {
      axios.post('/api/admin/position/held', state).then(() => {
        setErr(false)
        setShow(true)
        setState({politician_id: '',
        position: '',
        newPosition: false,
        current: true,
        startDate: '',
        endDate: '',
        datesValid: false})
        setValidated(false)
      })
      .catch(err => {
        console.log(err)
        setErr(true)
        setShow(true)
      })
    }
  }
  console.log('state',state)
  return (
    <Form noValidate validated={validated} onSubmit={handleSubmit} className="bg-secondary p-3 mt-3 text-light">
      <Row>
        <h2>Add Position Held</h2>
      </Row>
      <Row>
        <Form.Group as={Col} xs="12" md="6">
          <Form.Label>Politician</Form.Label>
          <Form.Select
          onChange={inputHandler}
          name='politician_id'
          value={state.politician_id}
          required>
            <option value=''>--</option>
            {props.politicians && props.politicians.sort((a,b) => a.fn.localeCompare(b.fn)).map((p) => {
              return <option key={p.politician_id} value={p.politician_id}>{p.fn + ' ' + p.ln}</option>
            })}
          </Form.Select>
        </Form.Group>
        {!state.newPosition ? (
          <Form.Group as={Col} xs="12" md="6">
            <Form.Label>Position</Form.Label>
            <Form.Select
            name='position'
            onChange={inputHandler}
            value={state.position}
            isValid={state.position.length > 0}
            >
              <option value=''>--</option>
              {props.positions && props.positions.sort((a,b) => a.position.localeCompare(b.position)).map((p) => {
                return (<option key={p.position} value={p.position}>{p.position}</option>)
              })}
              <option value='Add New' className='bg-warning'>Add New</option>
            </Form.Select>
          </Form.Group>
        ) : (
          <Form.Group as={Col}>
            <Form.Label>Create Position</Form.Label>
            <InputGroup>
            <InputGroup.Text onClick={() => setState({...state, newPosition: false})} className=' btn btn-danger'>Cancel</InputGroup.Text>
            <Form.Control 
            isValid={state.position.length > 0}
            type="text" name='position' onChange={inputHandler} placeholder="Governor" />
            </InputGroup>
          </Form.Group>
        )}
        <Form.Group
          as={Col}
          xs='12'
          className="align-self-end mt-2" 
          controlId="formBasicCheckbox"
          size='large'
        >
          <Form.Check
            checked={state.current}
            onChange={() => setState({...state, current: !state.current})}
            type="switch"
            
            label="Current Position"
            id='current-check-box'
          />
        </Form.Group>
      </Row>
      <Row>
        <Form.Group as={Col}>
          <Form.Label>Start Date</Form.Label>
          <Form.Control name='startDate' required onChange={inputHandler} type="date" value={state.startDate} placeholder="Donald" />
        </Form.Group>
        {!state.current ? (
          <Form.Group as={Col}>
            <Form.Label>End Date</Form.Label>
            <Form.Control required={state.current ? 'false' : 'true'} name='endDate' value={state.endDate} onChange={inputHandler} type="date" placeholder="Trump" />
          </Form.Group>
        ) : null}
      </Row>
      <Row>
        <Form.Group className="d-flex justify-content-end">
          <Button type="submit" className="mt-2">
            Add
          </Button>
        </Form.Group>
      </Row>
      <Row>
        <Col className='my-2'>
          {show ? (
            err ? (
              <Alert
                dismissible="true"
                variant="danger"
                onClose={() => setShow(false)}
              >
                Failed.
              </Alert>
            ) : (
              <Alert
                dismissible="true"
                variant="success"
                onClose={() => setShow(false)}
              >
                Success.
              </Alert>
            )
          ) : null}
        </Col>
      </Row>
    </Form>
  );
}
export default AddHeldPosition;

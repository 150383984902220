import React from "react";

import { Switch, Route } from "react-router-dom";

import Admin from "./Components/Admin/Admin";
import Landing from "./Components/Landing/Landing";
import WallPosts from "./Components/Walls/WallPosts";

import Endorsements from "./Components/Endorsements/Endorsements";
import GetInvolved from "./Components/GetInvolved/GetInvolved";
import Contact from "./Components/Contact/Contact";
import PolPage from "./Components/Politician/PolPage";
import SignUp from "./Components/SignIn/SignUp";
import Portal from "./Components/Portal/Portal";
import TwoTwoFive from "./Components/TwoTwoFive/TwoTwoFive";


export default (
  <Switch>
    <Route exact path="/" component={Landing} />
    <Route exact path="/admin" component={Admin} />
    <Route exact path="/walls" component={WallPosts} />
    <Route exact path="/endorsements" component={Endorsements} />
    <Route exact path="/join" component={GetInvolved} />
    <Route exact path="/contact" component={Contact} />
    <Route exact path="/signup" component={SignUp} />
    <Route exact path="/225" component={TwoTwoFive} />
    <Route exact path="/portal" component={Portal} />
    <Route exact path="/politician/:pol_id" component={PolPage} />
  </Switch>
);

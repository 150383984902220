import React, { useState, useEffect } from "react";
import "./PoliticianManager.scss";
import Button from "react-bootstrap/Button";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Alert from "react-bootstrap/Alert";
import InputGroup from "react-bootstrap/InputGroup";
import axios from "axios";
import Swal from "sweetalert2";
import { states } from "../../../../assets/shared";

function AddNewPolitician(props) {
  const [response, setResponse] = useState(false);
  const [err, setErr] = useState(false);
  const [validated, setValidated] = useState(false);
  const [state, setState] = useState({
    newPosition: false,
    position: "",
    fn: "",
    ln: "",
    party: "",
    img_url: "",
    state: ""
  });
  useEffect(() => {
    if(props.editMode) {
     const {politician_id, fn, ln,party,position, img_url} = props.pol
     setState({
       ...state,
       politician_id,
       fn,
       ln,
       party,
       position,
       img_url,
       state: props.pol.state
       
     })
    }
  },[])
   
  const handleSubmit = (e) => {
    e.preventDefault();
    let form = e.currentTarget;
    if (form.checkValidity() === false) {
      e.stopPropagation();
      setValidated(true);
    } else {
      if(props.editMode) {
        axios.put('/api/politician', state).then(res => {
          props.setEditModal(false)
          props.loadInfo()
          Swal.fire({
            position: 'top-center',
            icon: 'success',
            title: 'Politician information was changed successfully.',
            showConfirmButton: false,
            timer: 1500
          })
        })
      } else {

        axios
        .post("/api/admin/politician", state)
        .then((res) => {
           
           
          setErr(false);
          setResponse(true);
          setState({ fn: "", ln: "", party: "", img_url: "", position: '' , state: ''});
          setValidated(false);
          props.loadInfo()

        })
        .catch((err) => {
           
          setErr(true);
          setResponse(true);
        });
      }
    }
  };

  const handleInput = (e) => {
    if (e.target.name === "position" && e.target.value === "Add New") {
      setState({ ...state, newPosition: true });
    } else {
      setState({ ...state, [e.target.name]: e.target.value });
    }
  };
  console.log('state', state)
  return (
    <Form
      noValidate
      validated={validated}
      onSubmit={handleSubmit}
      className="bg-secondary p-3 mt-3 text-light"
    >
      <Row>
        <h2>{props.editMode ? 'Edit' : 'New'} Politician</h2>
      </Row>
      <Row>
        <Form.Group as={Col} xs="12" md="6">
          <Form.Label>First Name</Form.Label>
          <Form.Control
            required
            name="fn"
            value={state.fn}
            onChange={handleInput}
            type="text"
            placeholder="Donald"
          />
        </Form.Group>
        <Form.Group as={Col} xs="12" md="6">
          <Form.Label>Last Name</Form.Label>
          <Form.Control
            required
            name="ln"
            value={state.ln}
            type="text"
            onChange={handleInput}
            placeholder="Trump"
          />
        </Form.Group>
      </Row>
      <Row className="my-2">
        <Form.Group as={Col} xs="12" lg="6" className='align-self-end'>
          <Form.Label>Political Party</Form.Label>
          <Form.Select
            name="party"
            onChange={handleInput}
            value={state.party}
            required
          >
            <option value="">--</option>
            {props.parties &&
              props.parties.sort((a,b) => a.party.localeCompare(b.party)).map((p) => {
                return (
                  <option key={p.party} value={p.party}>
                    {p.party}
                  </option>
                );
              })}
          </Form.Select>
        </Form.Group>
        {!state.newPosition ? (
          <Form.Group as={Col} xs="12" lg="6" className='mt-2 align-self-end'>
            <Form.Label> Current Position</Form.Label>
            <Form.Select
              name="position"
              onChange={handleInput}
              value={state.position}
            >
              <option value="">--</option>
              {props.positions &&
                props.positions.sort((a,b) => a.position.localeCompare(b.position)).map((p) => {
                  return (
                    <option key={p.position} value={p.position}>
                      {p.position}
                    </option>
                  );
                })}
              <option value="Add New" className="bg-warning">
                Add New
              </option>
            </Form.Select>
          </Form.Group>
        ) : (
          <Form.Group as={Col} xs="12" lg="6">
            <Form.Label>Create Position</Form.Label>
            <InputGroup>
              <InputGroup.Text
                onClick={() => setState({ ...state, newPosition: false })}
                className=" btn btn-danger"
              >
                Cancel
              </InputGroup.Text>
              <Form.Control
                type="text"
                name="position"
                onChange={handleInput}
                placeholder="Governor"
              />
            </InputGroup>
          </Form.Group>
        )}
      </Row>
      <Row>
      <Form.Group as={Col} xs="6" md="4" className="mb-2">
                <Form.Label>State or USA</Form.Label>
                <Form.Select
                  required
                  value={state.state}
                  name="state"
                  onChange={handleInput}
                >
                  <option key="select" value="">
                    --
                  </option>
                  <option key="USA" value="USA">
                    USA
                  </option>
                  <option key="NA" value="N/A">
                    N/A
                  </option>
                  <hr />
                  {states.map((s) => {
                    return <option key={s} value={s}>{s}</option>;
                  })}
                </Form.Select>
              </Form.Group>
      </Row>
      <Form.Group as={Col} xs="12">
        <Form.Label>Image URL</Form.Label>
        <Form.Control
          name="img_url"
          onChange={handleInput}
          value={state.img_url}
          type="text"
          placeholder="https://link-to-img.com/"
        />
      </Form.Group>
      <Row>
        <Col className="d-flex justify-content-center">
          {state.img_url.length > 0 ? (
            <img
              src={state.img_url}
              className="mt-3"
              id="politician-img-preview"
            />
          ) : null}
        </Col>
      </Row>
      <Form.Group className="d-flex justify-content-end">
        <Button type="submit" className="mt-2">
          {props.editMode ? 'Submit Changes' : 'Add'}
        </Button>
      </Form.Group>
      <Row className='my-2'>
        <Col>
          <Col>
            {response ? (
              err ? (
                <Alert
                  dismissible="true"
                  variant="danger"
                  onClose={() => setResponse(false)}
                >
                  Failed.
                </Alert>
              ) : (
                <Alert
                  dismissible="true"
                  variant="success"
                  onClose={() => setResponse(false)}
                >
                  Success.
                </Alert>
              )
            ) : null}
          </Col>
        </Col>
      </Row>
    </Form>
  );
}

export default AddNewPolitician;

import React, { useState } from "react";
import Container from "react-bootstrap/Container";
import Button from "react-bootstrap/Button";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Alert from "react-bootstrap/Alert";

import axios from "axios";

function AddParty() {
  const [validated, setValidated] = useState(false);
  const [response, setResponse] = useState(false);
  const [err, setErr] = useState(false);

  const [show, setShow] = useState(false);

  const [state, setState] = useState({
    party: "",
    noun: "",
  });
  const handleSubmit = (e) => {
    e.preventDefault();
    let form = e.currentTarget;
    if (form.checkValidity() === false) {
      e.stopPropagation();
      setValidated(true);
    } else {
      axios
        .post("/api/admin/party", state)
        .then((res) => {
           
          setErr(false);
          setShow(true);
          setState({ party: "", noun: "" });
          setValidated(false);
        })
        .catch((err) => {
           
          setErr(true);
          setShow(true);
        });
    }
  };

  const handleInput = (e) => {
    setState({ ...state, [e.target.name]: e.target.value });
  };
  return (
    <Form
      noValidate
      validated={validated}
      onSubmit={handleSubmit}
      className="bg-secondary p-3 mt-3 text-light"
    >
      <Row>
        <h2>New Party</h2>
      </Row>
      <Row>
        <Form.Group
          as={Col}
          xs="12"
          xl="6"
          className="d-flex flex-column justify-content-end"
        >
          <Form.Label>
            Party Name{" "}
            <span className="text-info">
              <br /> Example: The{" "}
              <strong>
                <em>Democratic</em>
              </strong>{" "}
              party.{" "}
            </span>
          </Form.Label>
          <Form.Control
            required
            type="text"
            onChange={handleInput}
            value={state.party}
            name="party"
            placeholder="Democratic"
          />
        </Form.Group>
        <Form.Group
          as={Col}
          xs="12"
          xl="6"
          className="d-flex flex-column justify-content-end mt-2"
        >
          <Form.Label>
            Party Noun <br />{" "}
            <span className="text-info">
              {" "}
              Example: He is a{" "}
              <strong>
                <em>Democrat.</em>
              </strong>
            </span>
          </Form.Label>
          <Form.Control
            required
            type="text"
            name="noun"
            value={state.noun}
            onChange={handleInput}
            placeholder="Democrat"
          />
        </Form.Group>
        <Form.Group className="d-flex justify-content-end">
          <Button type="submit" className="mt-2">
            Add
          </Button>
        </Form.Group>
      </Row>
      <Row>
        <Col>
          {show ? (
            err ? (
              <Alert
                dismissible="true"
                variant="danger"
                onClose={() => setShow(false)}
              >
                Failed.
              </Alert>
            ) : (
              <Alert
                dismissible="true"
                variant="success"
                onClose={() => setShow(false)}
              >
                Success.
              </Alert>
            )
          ) : null}
        </Col>
      </Row>
    </Form>
  );
}

export default AddParty;

import React, { useState, useEffect } from "react";
import axios from "axios";
import {
  Button,
  Container,
  FormControl,
  ListGroup,
  Spinner,
} from "react-bootstrap";
import UserNote from "./UserNote";

const UserNotes = ({ user_id }) => {
  const [notes, setNotes] = useState([]);
  const [newNote, setNewNote] = useState("");

  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    fetchUserNotes();
  }, []);

  const fetchUserNotes = async () => {
    try {
      const response = await axios.get(`/api/user/${user_id}/notes`);
      setNotes(response.data);
      setLoaded(true);
    } catch (error) {
      console.error(error);
    }
  };

  const createNote = async () => {
    try {
      await axios.post(`/api/user/${user_id}/notes`, { note: newNote });
      setNewNote("");
      fetchUserNotes();
    } catch (error) {
      console.error(error);
    }
  };


  return (
    <div>
      <h2>
        <u>User Notes</u>
      </h2>
      <div>
        <h4 className="d-inline me-3">Create Note</h4>
        <input
          className="rounded-3"
          type="textarea"
          value={newNote}
          onChange={(e) => setNewNote(e.target.value)}
        />
        <Button onClick={createNote} className="btn-sm">
          Create
        </Button>
      </div>
      <div>
        <ListGroup>
          {loaded ? (
            notes?.length > 0 &&
            notes?.map((note) => (
              <UserNote
                key={note.note_id}
                note={note}
                fetchUserNotes={fetchUserNotes}
              />
            ))
          ) : (
            <Container className="main-content d-flex justify-content-center">
              <Spinner />
            </Container>
          )}
        </ListGroup>
      </div>
    </div>
  );
};

export default UserNotes;

import React, { useState } from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
function Endorsement(props) {
  const { endorsement } = props;
  const [fileModal, setFileModal] = useState({ show: false, isDoc: false });
  const viewFile = () => {
    let isDoc = false;
    if (
      endorsement.file_url.includes(".doc") ||
      endorsement.file_url.includes(".docx")
    ) {
      isDoc = true;
    }
    setFileModal({ show: true, isDoc });
  };
  return (
    <>
      <Row className="text-dark">
        <Col xs="12">
          <section>
            <p>
              <strong>
                <u className="text-danger">{endorsement.position}</u>:{" "}
                {endorsement.fn + " " + endorsement.ln}:{" "}
              </strong>
              {endorsement.body_text}{" "}
              {endorsement.link ? (
                <a href={endorsement.link}>See more.</a>
              ) : null}
              {endorsement.file_url && (
                <p
                  className="pointer m-0 p-0"
                  onClick={() => {
                    viewFile();
                  }}
                >
                  <u>View attached file.</u>
                </p>
              )}
            </p>
          </section>
        </Col>
      </Row>
      <Modal
        show={fileModal.show}
        onHide={() => setFileModal({ show: false, isDoc: false })}
        fullscreen
        centered
      >
        <Modal.Header closeButton>
          <h4>
            {endorsement.file_name}{" "}
            <a href={endorsement.file_url} target="_blank" rel="noreferrer">
              {fileModal.isDoc ? "Download" : "Open in a new tab."}
            </a>
          </h4>
        </Modal.Header>
        <Modal.Body>
          <iframe
            id="file-iframe"
            title={endorsement.fn + endorsement.ln}
            src={fileModal.isDoc ? `https://docs.google.com/gview?url=${endorsement.file_url}&embedded=true` : `${endorsement.file_url}#toolbar=0&navpanes=0&scrollbar=0`}
          />
          
        </Modal.Body>
      
      </Modal>
    </>
  );
}

export default Endorsement;

import React from "react";
import Container from "react-bootstrap/Container";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import Badge from "react-bootstrap/Badge";
import DonateButton from "../Donate/DonateButton";
// let flyerUrl =
//   "https://firebasestorage.googleapis.com/v0/b/ccuc-5c7d5.appspot.com/o/Mike%20Lee%20Flyer%20(PDF%20Standard).pdf?alt=media&token=f5e952fe-2858-425b-911d-f55774f25beb";
  let flyerUrl = "https://firebasestorage.googleapis.com/v0/b/ccuc-5c7d5.appspot.com/o/StatePrimary2024.pdf?alt=media&token=23925920-615b-4e9a-8bff-952b65f60912"
function TwoTwoFive() {
  return (
    <Container>
      <h1>The 2-2-5 Program</h1>
      <p>
        Americans!! The time to stand up for your freedoms is now!
        Constitutional Conservatives of Utah County (CCUC) is proud to announce
        its 2-2-5 program. Right now, the Constitution is hanging by a thread.
        There are very few members of Congress that refer to the Constitution
        when they are faced with a decision or a vote on a bill. US Senator from
        Utah, Mike Lee is the exception to this rule. The radical left is trying
        to remove the constraints of the checks and balances that our inspired
        Constitution supports (see flyer below). We are encouraging all those
        who love Freedom, The Constitution and our Country to stand up NOW
        against the left that is spending millions of dollars to get their candidates in office.
      </p>
      <h4>We encourage each freedom-loving American to do the following:</h4>
      <ol>
        <li>
          <a href={flyerUrl} target="_blank" rel="noopener noreferrer">
            {" "}
            Buy or print 100 of these flyers
          </a>
          . Our cost is <b>$2</b> for 100 black and white copies. You can print
          your own at your own expense or you can pay CCUC and we will print
          them and deliver them to you. Just email{" "}
          <a href="mailto:contact@utahconservatives.org">
            contact@utahconservatives.org
          </a>{" "}
          and include your name address and phone number and we will print them
          for you. If you choose to pay $5 you can get full color copies instead
          of black and white. You can pay for your copies at the following link:
          <DonateButton />
        </li>
        <li>
          Spend <b>2 hours</b> delivering flyers to your neighborhood. It takes
          about 2 hours for you and maybe some family members helping to deliver
          100 flyers in your neighborhoods. It is important to get this step
          done quickly as the ballots have already arrived for most residents.
        </li>
        <li>
          <b>Encourage 5 others</b> to join in the effort of the 2-2-5 program.
          Speak with others about the current situation and get their commitment
          to go to{" "}
          <a href="http://www.utahconservatives.org/225">
            www.utahconservatives.org/225
          </a>{" "}
          and join in the effort. Coordinate with your neighbors to cover
          different neighborhoods so that each area is delivered once. Go to the{" "}
          <a href="https://www.utahconservatives.org">
            www.utahconservatives.org
          </a>{" "}
          website and search by Donald
          Trump, Joe Biden, or any other politician to see talking points about
          both of their past actions. This will help in your conversations. 
        </li>
      </ol>
      <h6>Here
          is an example of a past flyer:</h6>
      <iframe
        style={{ height: "80vh", width: "100%" }}
        src={flyerUrl + "#zoom=FitH"}
        title="Utah Conservatives Flyer"
        frameborder="0"
      ></iframe>
      <a href={flyerUrl} target="_blank" rel="noopener noreferrer">
        Click here to see the flyer in a new tab.
      </a>
    </Container>
  );
}

export default TwoTwoFive;

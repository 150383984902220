import React from "react";
import Post from "../Posts/Post";

function WallOfShame(props) {
  const { shame } = props;
  return (
    <>
      <h3 className="text-center bg-danger p-2 text-light rounded">
        Wall of Shame
      </h3>

      {shame &&
        shame.map((p) => {
          return <Post key={p.post_id} post={p} />;
        })}
    </>
  );
}

export default WallOfShame;

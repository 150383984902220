import React, { useState, useEffect } from "react";
// import "./UserSignUp.scss";
import Container from "react-bootstrap/Container";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import FloatingLabel from "react-bootstrap/FloatingLabel";
import Button from "react-bootstrap/Button";
import axios from "axios";
import { withRouter } from "react-router-dom";
// import { useUser } from "../UserContext";
import {
  getAuth,
  signInWithCustomToken,
  setPersistence,
  browserLocalPersistence,
} from "firebase/auth";
import Swal from "sweetalert2";
import { phoneFormat } from "../../commonFunctions";
import { states } from "../../assets/shared";

// todo
// address
// login signin links
// checkboxes /input for what they are interested in doing.

function SignUp(props) {
  //   let user = useUser();
  const [state, setState] = useState({
    firstName: "",
    lastName: "",
    phone: "",
    email: "",
    password: "",
    password2: "",
    street: "",
    city: "",
    state: "Utah",
    zip: "",
  });
  const [why, setWhy] = useState(false);
  const [interests, setInterests] = useState([]);
  const [validated, setValidated] = useState(false);
  const [passwordError, setPasswordError] = useState({
    message: "",
    isError: false,
  });
  const auth = getAuth();

  const handleInput = (e) => {
    if (e.target.name === "phone") {
      setState({ ...state, phone: phoneFormat(e.target.value) });
    } else {
      setState({ ...state, [e.target.name]: e.target.value });
    }
  };

  const validatePassword = () => {
    if (state.password.length > 5) {
      setPasswordError({ ...passwordError, isError: false });
      if (state.password === state.password2) {
        setPasswordError({ ...passwordError, isError: false });
        return true;
      } else {
        setPasswordError({
          ...passwordError,
          message: "Passwords do not match",
          isError: true,
        });
      }
    } else {
      setPasswordError({
        ...passwordError,
        message: "Password must be at least 6 characters.",
        isError: true,
      });
    }
    return false;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    let form = e.currentTarget;
    if (form.checkValidity() === false) {
      e.stopPropagation();
      setValidated(true);
      validatePassword();
    } else if (validatePassword()) {
      submitNewUser();
    } else {
      setValidated();
    }
  };

  const submitNewUser = () => {
    let body = {...state, interests}
    axios
      .post("/api/auth/new/user", body)
      .then(async (res) => {
        console.log("res.data", res.data);
        await setPersistence(auth, browserLocalPersistence).then(() => {
          signInWithCustomToken(auth, res.data.token)
            .then((userCred) => {
              console.log("Logged into Firebase.");
            })
            .catch((err) => console.log("Firebase error.", err));
        });
        if (res.data.user_id) {
          props.history.push("/portal");
        } else if (res.data.user_id && state.apply) {
          props.history.push("/apply");
        }
      })
      .catch((err) => {
        console.log(err.response.data);
        Swal.fire({
          icon: "error",
          title: "Unable to Create Account",
          text: "That email is already in use.",
          timer: 3000,
        });
      });
  };
  const handleInterests = (value) => {
    let tempInterests = [...interests]
    if(interests.includes(value)){
      tempInterests = tempInterests.filter(int => int !== value)
      setInterests(tempInterests)
      return
    }
    if (value === "none") {
      Swal.fire({
        icon: "question",
        text: "By selecting this, you will erase the previous items, continue with selection?",
        showCancelButton: true,
      }).then((res) => {
        if (res.isConfirmed) {
          setInterests(["none"]);
        }
      });
      return;
    } else {
      tempInterests = interests.filter((int) => int !== "none");
      tempInterests = [...tempInterests, value];
      setInterests(tempInterests);
    }
  };
  console.log('interests: ', interests)
  return (
    <Row className="d-flex justify-content-center p-4">
      <Col xs="12" lg="6">
        <Form
          onSubmit={handleSubmit}
          noValidate
          validated={validated}
          className=" bg-light p-md-3 pt-3 "
        >
          <h3 className="text-center">Create an Account</h3>
          <p>
            User accounts are intended to help us distribute tasks and help
            further our agenda. You may be contacted after signing up to find
            out how you can be involved.
            <em> Roles will be assigned after account creation.</em>
          </p>

          <FloatingLabel className="mb-2" label="First Name">
            <Form.Control
              required
              type="text"
              name="firstName"
              value={state.firstName}
              onChange={handleInput}
            />
          </FloatingLabel>
          <FloatingLabel className="mb-2" label="Last Name">
            <Form.Control
              required
              type="text"
              name="lastName"
              value={state.lastName}
              onChange={handleInput}
            />
          </FloatingLabel>
          <FloatingLabel className="mb-2" label="Phone">
            <Form.Control
              required
              type="tel"
              name="phone"
              value={state.phone}
              onChange={handleInput}
            />
          </FloatingLabel>
          <FloatingLabel
            className="mb-2"
            label="Email"
            placeholder="you@email.com"
          >
            <Form.Control
              required
              type="email"
              name="email"
              value={state.email}
              onChange={handleInput}
              autoComplete="email"
            />
          </FloatingLabel>
          <p className="text-start m-0 p-0">Address Information </p>
          <p className="m-0 p-0">
            <u onClick={() => setWhy(!why)} className=" pointer hover-blue">
              Why do we ask for this?
            </u>
          </p>
          {why && (
            <p>
              We ask for address information to know which areas you are
              involved in and how we can work with you in the future.
            </p>
          )}
          <Row>
            <Form.Group className="mb-2" label="Street" as={Col} xs="12">
              <Form.Label className="text-start">Street</Form.Label>
              <Form.Control
                required
                type="text"
                name="street"
                value={state.street}
                onChange={handleInput}
              />
            </Form.Group>
            <Form.Group as={Col} xs="12" md="4" className="mb-2 ">
              <Form.Label>City</Form.Label>
              <Form.Control
                required
                type="text"
                name="city"
                value={state.city}
                onChange={handleInput}
              />
            </Form.Group>
            <Form.Group className="mb-2" as={Col} xs="12" md="4">
              <Form.Label>State</Form.Label>

              <Form.Select
                required
                value={state.state}
                name="state"
                onChange={handleInput}
              >
                <option key="select" value="">
                  --
                </option>

                
                {states.map((s) => {
                  return (
                    <option key={s} value={s}>
                      {s}
                    </option>
                  );
                })}
              </Form.Select>
            </Form.Group>
            <Form.Group className="mb-2" as={Col} xs="12" md="4">
              <Form.Label>Zipcode</Form.Label>
              <Form.Control
                required
                type="text"
                name="zip"
                value={state.zip}
                onChange={handleInput}
              />
            </Form.Group>
          </Row>
          <FloatingLabel
            className="mb-2"
            label="Password"
            placeholder="Password"
          >
            <Form.Control
              required={state.newPassword}
              isValid={!passwordError}
              minLength="6"
              type="password"
              name="password"
              value={state.password}
              onChange={handleInput}
            />
          </FloatingLabel>
          <FloatingLabel
            className="mb-2"
            label="Re-enter Password"
            placeholder="Password"
          >
            <Form.Control
              isValid={!passwordError}
              minLength="6"
              required={state.newPassword}
              type="password"
              name="password2"
              value={state.password2}
              onChange={handleInput}
            />
          </FloatingLabel>
          {passwordError.isError ? (
            <p className="text-danger mx-0">{passwordError.message}</p>
          ) : state.password.length < 6 ? (
            <p className="text-secondary">
              Password must be at least 6 characters.
            </p>
          ) : null}
          <hr />
          <p>How would you like to contribute?</p>
          <Container className="border border-secondary rounded-3 ps-2 py-3">
            <Form.Check
              onChange={() => handleInterests("flyers")}
              checked={interests.includes('flyers')}
              value='flyers'
              className="my-2"
              type="checkbox"
              label="Handing out flyers"
            />
            <Form.Check
              onChange={() => handleInterests("fundraising")}
              value='fundraising'
              className="my-2"
              type="checkbox"
              checked={interests.includes('fundraising')}
              label="Fundraising"
            />
            <Form.Check
              onChange={() => handleInterests("donations")}
              value='donations'
              className="my-2"
              checked={interests.includes('donations')}
              type="checkbox"
              label="Making Donations"
            />
            <Form.Check
              onChange={() => handleInterests("posts")}
              value='posts'
              className="my-2"
              checked={interests.includes('posts')}
              type="checkbox"
              label="Website Posts"
            />
            <Form.Check
              onChange={() => handleInterests("other")}
              checked={interests.includes('other')}
              value='other'
              className="my-2"
              type="checkbox"
              label="Other"
            />
            <hr />
            <Form.Check
              onChange={() => handleInterests("none")}
              value='none'
              checked={interests.includes('none')}

              className="my-2"
              type="checkbox"
              label="Not interested in helping out right now."
            />
          </Container>
          <Button className="my-2 float-end" type="submit">
            Create Account
          </Button>
        </Form>
      </Col>
    </Row>
  );
}

export default withRouter(SignUp);

import React, { useEffect, useState } from "react";
import AddNewPolitician from "../Admin/Tools/PoliticianManager/AddNewPolitician";
import Container from "react-bootstrap/Container";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import Spinner from "react-bootstrap/Spinner";
import AddEditPostForm from "../Admin/Tools/PostManager/AddEditPostForm";
import axios from "axios";
function Permissions({ user }) {
  const [info, setInfo] = useState();
  const [loaded, setLoaded] = useState(false);
  
  useEffect(() => {
    loadInfo();
  }, []);
  const loadInfo = () => {
    axios
      .get("/api/admin/politician")
      .then((res) => {
        setInfo(res.data);
        setLoaded(true);
      })
      .catch((err) => console.log(err));
  };

  return (
    <>
      <h6>As a member, you have the following permissions: </h6>
      <Row>
        <Col xs="12" lg="6" className='pt-3'>
          <AddEditPostForm memberMode={true} user={user} info={info}  />
        </Col>
        <Col xs="12" lg="6">
            {loaded && info ? (

                <AddNewPolitician
                loadInfo={loadInfo}
                parties={info.parties}
                positions={info.positions}
                />
                ) : (
                    <Row className='d-flex justify-content-center'>

                        <Spinner animation='grow'/>
                    </Row>
                )}
        </Col>
      </Row>
    </>
  );
}

export default Permissions;

import React, { useState, useEffect } from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import WallOfShame from "./WallOfShame";
import WallOfFame from "./WallOfFame";
import axios from "axios";
import "./WallPosts.scss";
import Form from "react-bootstrap/Form";
import Spinner from "react-bootstrap/Spinner";
import Container from "react-bootstrap/Container";
import { Dropdown } from "react-bootstrap";

function WallPosts() {
  const [posts, setPosts] = useState();
  const [fame, setFame] = useState();
  const [sortBy, setSortBy] = useState("Newest to Oldest")
  const [shame, setShame] = useState();
  const [loaded, setLoaded] = useState();
  const [pols, setPols] = useState();
  const [count, setCount] = useState({ fameCount: 0, shameCount: 0 });
  
  const [filteredPosts, setFilteredPosts] = useState();
  const [filteredInput, setFilteredInput] = useState("");
  useEffect(() => {
    getAllPosts();
  }, []);
  useEffect(() => {
    if (posts) {
      filterPostsBySearch();
    }
  }, [filteredInput]);
 
  const [filterInput, setFilterInput] = useState("");
  const getAllPosts = () => {
    axios.get("/api/posts").then((res) => {
      let sorted = res.data.sort((a,b) => new Date(b.event_date) - new Date(a.event_date))
      fameShameFilter(sorted);
      setFilteredPosts(sorted)
      setPosts(sorted);
     

      setLoaded(true);
    });
    axios.get("/api/politicians").then((res) => {
      setPols(res.data);
    });
  };

  const fameShameFilter = (unfiltered) => {
    let shame = unfiltered.filter((p) => p.wall_type === "shame");
    let fame = unfiltered.filter((p) => p.wall_type === "fame");
    setShame(shame);
    setFame(fame);
  };
  //  .options : null);

  const filterPostsBySearch = () => {
    let splitInput = filteredInput.split(" ");
    let filtered = posts.filter((p) => {
      let fullName = p.fn + " " + p.ln;
      fullName = fullName.toLowerCase();

      if (
        fullName.includes(filteredInput.toLowerCase())
       
      ) {
        return true;
      } else {
        return false;
      }
    });
    if (filtered.length < 1) {
      filtered = posts;
    }

    setFilteredPosts(filtered);
    fameShameFilter(filtered);
  };
  const handleSort = (sort) => {
    if(sort === 'newest'){
      setSortBy("Newest to Oldest")
      fameShameFilter(filteredPosts.sort((a,b)=> new Date(b.event_date) - new Date(a.event_date)))

    } else {
      
      setSortBy("Oldest to Newest")
      fameShameFilter(filteredPosts.sort((a,b)=> new Date(a.event_date) - new Date(b.event_date)))

    }
  }

  return loaded ? (
    <Container fluid={true} className="wall-posts rounded-3 p-3 m-0">
      <Row>
        <Col xs="12" id="search-box-col">
          <datalist id="politicians">
            {pols &&
              pols
                .sort((a, b) => a.fn.localeCompare(b.fn))
                .map((p) => {
                  return <option key={p.id}>{p.fn + " " + p.ln}</option>;
                })}
          </datalist>
          <Form.Control
            autoComplete="on"
            list="politicians"
            placeholder="Type to search..."
            value={filteredInput}
            onChange={(e) => setFilteredInput(e.target.value)}
            autoFocus={true}
          />
          {/* </div> */}
        </Col>
      </Row>
      <Row>
        <Col xs='12' className='d-flex justify-content-end'>
          <Dropdown>
            <Dropdown.Toggle variant="light" className=' shadow mb-3'id="dropdown-basic">
              {sortBy}
            </Dropdown.Toggle>

            <Dropdown.Menu>
              <Dropdown.Item onClick={() => handleSort('newest')}>Newest to Oldest</Dropdown.Item>
              <Dropdown.Item onClick={() => handleSort('oldest')}>Oldest to Newest</Dropdown.Item>
              
            </Dropdown.Menu>
          </Dropdown>
        </Col>
      </Row>
      <Row>
        <Col xs="12" md="6">
          <h4>Results: {fame.length}</h4>
          <WallOfFame fame={fame} />
        </Col>
        <Col xs="12" md="6">
          <h4>Results: {shame.length}</h4>
          <WallOfShame shame={shame} />
        </Col>
      </Row>
    </Container>
  ) : (
    <Row>
      <Spinner className="mx-auto" animation="grow" variant="primary" />
    </Row>
  );
}

export default WallPosts;

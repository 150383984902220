import React, { useState, useEffect } from "react";
import Container from "react-bootstrap/Container";
import Button from "react-bootstrap/Button";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Spinner from 'react-bootstrap/Spinner'
import PostManager from "./Tools/PostManager/PostManager";
import UserManager from "./Tools/UserManager/UserManager";
import EndorsementManager from "./Tools/EndorsementManager/EndorsementManager";
import PoliticianManager from "./Tools/PoliticianManager/PoliticianManager";
import axios from "axios";
import { withRouter } from "react-router";

function Admin(props) {
    const [loaded, setLoaded] = useState(false)
    const [tool, setTool] = useState('posts')
  useEffect(() => {
    checkUser()
  },[])
  const checkUser = () => {
    axios.get('/api/auth/user').then(res => {
      setLoaded(true)
      if(res.data.role !== 'admin'){
        props.history.push('/')
      } 
    }).catch(err => props.history.push('/'))
  }
  const ToolRouter = () => {
    switch (tool) {
      case "posts":
        return (<PostManager />);
      case "politicians":
        return (<PoliticianManager />);
      case "users":
       return ( <UserManager /> );
      case "endorsements":
       return (<EndorsementManager />);
      default:
        return (<PostManager />);
    }
  };
  return (
    loaded ? (
      
      
      <Container className='p-0' >
      <Row>
        <Col xs='12' sm='6' md='3'>
          <Button variant="primary" onClick={() => setTool('posts')} className="my-1 w-100">
            Posts
          </Button>
        </Col>
        <Col xs='12' sm='6' md='3'>
          <Button variant="secondary" onClick={() => setTool('politicians')} className=" my-1 w-100">
            Politicians
          </Button>
        </Col>
        <Col xs='12' sm='6' md='3'>
          <Button variant="success" onClick={() => setTool('endorsements')} className="my-1 w-100">
            Endorsements
          </Button>
        </Col>
        <Col xs='12' sm='6' md='3'>
          <Button variant="warning" onClick={() => setTool('users')} className=" my-1 w-100">
            Users
          </Button>
        </Col>
      </Row>
      <Row><ToolRouter/></Row>
      </Container>
      ) : (
        <Row className='d-flex justify-content-center'>
          <Spinner variant='primary' animation='grow' />
        </Row>
      )
  );
}

export default withRouter(Admin);

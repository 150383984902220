import React from "react";
import Post from "../Posts/Post";

function WallOfFame(props) {
    const {fame} = props
    
  return (
    <>
      
      <h3  className="text-center bg-primary p-2 text-light rounded">
        Wall of Fame
      </h3>
      {fame &&
        fame.map((p) => {
          return <Post key={p.post_id} post={p} />;
        })}
    </>
  );
}

export default WallOfFame;

import React, { useEffect, useState } from "react";
import Container from "react-bootstrap/Container";
import Button from "react-bootstrap/Button";
import axios from "axios";

import { Spinner, Row, Col } from "react-bootstrap";

import Form from "react-bootstrap/Form";
import AddEditPostForm from "./AddEditPostForm";
import PostTable from "./PostTable";
function PostManager() {
  const [loaded, setLoaded] = useState(false);
  const [posts, setPosts] = useState();

  useEffect(() => {
    loadPosts();
  }, []);

  const loadPosts = () => {
    setLoaded(false);
    axios.get("/api/posts").then((res) => {
      setPosts(res.data);
      setLoaded(true);
    });
  };
  return (
    <Container>
      <Row>
        <Col>
          <AddEditPostForm loadPosts={loadPosts} />
        </Col>
      </Row>
      <Row>
        {posts ? (
          <Col>
            <h4>Posts</h4>
            <PostTable loadPosts={loadPosts} posts={posts} loaded={loaded} />
          </Col>
        ) : (
          <Container>
            <Row className="d-flex justify-content-center my-5">
              <Spinner animation="grow" />
            </Row>
          </Container>
        )}
      </Row>
    </Container>
  );
}

export default PostManager;

import { useState, useEffect } from "react";
import { projectStorage } from "../firebase.js";
import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import Swal from "sweetalert2";
const useStorage = (file) => {
  const [progress, setProgress] = useState(0);
  const [error, setError] = useState(null);
  const [url, setUrl] = useState(null);
  useEffect(() => {
    //references (gives the file this name when in the bucket)
    const storageRef = ref(projectStorage, file.name);
    ///change this to a variable from props for documents/insurance and whatever else.
    // const collectionRef = 'images'
    // let userId = 20
    //uploads to the bucket with name
    const uploadTask = uploadBytesResumable(storageRef, file);
    uploadTask.on(
      "state_changed",
      (snapshot) => {
        //this is for progress of upload
        let percentage =
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        setProgress(percentage);
      },
      (err) => {
        Swal.fire({
          icon: "error",
          title: "You were signed out.",
          text: "Please sign in again.",
          timer: 3000,
        }).then(value => {
          if(value.isConfirmed){
          window.location.href = '/'

          } else {

            window.location.href = '/'
          }
        })
        
        setError(err);
      },
      async () => {
        //gets the url of the uploaded file
        await getDownloadURL(uploadTask.snapshot.ref)
          .then(async (url) => {
            setUrl(url);
          })
          .catch((e) => {
         

            console.error(e);
          });
      }
    );
  }, [file]);
  return { progress, url, error };
};

export default useStorage;

import React, { useState, useEffect } from "react";
import ComingSoon from "../ComingSoon/ComingSoon";
import Endorsement from "./Endorsement";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import axios from "axios";
import { Container } from "react-bootstrap";
function Endorsements() {
  const [endorsements, setEndorsements] = useState();
  useEffect(() => {
    loadData();
  }, []);
  const loadData = () => {
    axios.get("/api/endorsements").then((res) => {
      setEndorsements(res.data);
    });
  };
  return (
    <Container>
      <Row>
        <Col xs="12">
          <hr />
          <h3>Endorsements</h3>{" "}
          <p>
            Endorsements made by Constitutional Conservatives of Utah County are
            not taken lightly. We currently have an 8-member board and each one
            of the board members has extensive experience in Utah politics. Most
            have held elected positions in the past, although we don’t allow
            anyone currently serving in an elected position to be on the board.{" "}
          </p>
          <p>
            Through this experience, collectively, we know who has fought on the
            side of Constitutional government and who has supported individual
            freedom over the years. In order to be endorsed by our group, a
            candidate must have no more than one “nay” vote from the board
            regarding their endorsement, putting a very high hurdle for our
            endorsements.
          </p>
          <p>
            {" "}
            Therefore, an endorsement should be seen as an honor from any
            candidate and those who view themselves as conservatives supporting
            Constitutional government should have some confidence that the
            endorsed candidate is likely to support individual freedom and
            Constitutional Principles as they fulfill their duties as an elected
            official.
          </p>
          <p className="my-2">
            After long deliberation and much discussion, the board of
            Constitutional Conservatives of Utah County has finalized our
            endorsements for the upcoming elections as follows:
          </p>
        </Col>
      </Row>
      <hr />
      {endorsements &&
        endorsements.map((e) => {
          return <Endorsement endorsement={e} />;
        })}
    </Container>
  );
}

export default Endorsements;

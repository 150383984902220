import axios from "axios";
import { set } from "prerender-node";
import React, { useEffect, useState } from "react";
import { Container, FormCheck, ListGroup, Spinner } from "react-bootstrap";
import Swal from "sweetalert2";

function UserTasks({ user_id }) {
  const [loading, setLoading] = useState(false);
  const [tasks, setTasks] = useState([]);
  const [userTasks, setUserTasks] = useState([]);
  const [selectedTask, setSelectedTask] = useState(null);
  const loadData = () => {
    setLoading(true);
    if (!tasks[0]) {
      axios.get("/api/tasks").then((res) => {
        setTasks(res.data);
      });
    }
    axios.get("/api/tasks/user/" + user_id).then((res) => {
      setUserTasks(res.data);
    });
    setLoading(false);
  };
  useEffect(() => {
    loadData();
  }, []);
  console.log("tasks", tasks);
  console.log("userTasks", userTasks);
  const handleTaskCheck = (task) => {
    setLoading(true);
    setSelectedTask(task.task_id);
    axios
      .put(`/api/tasks/${task.task_id}/user/${user_id}`)
      .then(() => {
        let tempUserTasks = [...userTasks, task];
        setUserTasks(tempUserTasks);
        setLoading(false);
        loadData();
        setSelectedTask(null);
      })
      .catch((err) => {
        console.log(err);
        Swal.fire({
          icon: "error",
          title: "Error updating task.",
          text: "Please try again.",
          timer: 1000,
        });

        setLoading(false);
      });
  };
  return (
    <ListGroup className='my-2'>
      {tasks[0] ? (
        tasks.map((task) => (
       
        <ListGroup.Item key={task.task_id}>
  <div className="checkbox-or-spinner ">
    {!loading ? (
      <FormCheck
        type="checkbox"
        className="d-inline me-2 pointer"
        onClick={() => handleTaskCheck(task)}
        checked={userTasks.some(
          (userTask) =>
            userTask.task_id === task.task_id &&
            userTask.completed === true
        )}
      />
    ) : selectedTask === task.task_id && (
      <Spinner size='sm' animation='border' variant='primary'/>
    )}
  </div>
  {task.task_description}
</ListGroup.Item>
        ))
      ) : (
        <Container className="main-content d-flex justify-content-center">
          <Spinner animation="grow" />
        </Container>
      )}
    </ListGroup>
  );
}

export default UserTasks;

import React, { useEffect, useState } from "react";
import Container from "react-bootstrap/Container";

import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Spinner from "react-bootstrap/Spinner";
import axios from "axios";
import AddEditEndorsement from "./AddEditEndorsement";
import EndorsementTable from "./EndorsementTable";
function EndorsementManager() {
  const [endorsements, setEndorsments] = useState();
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    loadEndorsements();
  }, []);

  const loadEndorsements = () => {
    setLoaded(false);
    axios.get("/api/endorsements").then((res) => {
      setEndorsments(res.data);
    });
    setLoaded(true);
  };

  return (
    <Container>
      <AddEditEndorsement loadEndorsements={loadEndorsements} />
      <Row>
        <Col>
          <h4 className="my-1">Endorsements</h4>
          {loaded ? (
            <EndorsementTable
              endorsements={endorsements}
              loadEndorsements={loadEndorsements}
            />
          ) : (
            <Row className="d-flex justify-content-center">
              <Spinner animation="grow" />
            </Row>
          )}
        </Col>
      </Row>
    </Container>
  );
}

export default EndorsementManager;

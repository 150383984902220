import React, { useState } from "react";
import Progress from "./Progress";
import Form from "react-bootstrap/Form";


function Upload(props) {
  const [file, setFile] = useState(null);
  const [error, setError] = useState(null);
  const types = [
    "image/png",
    "image/jpeg",
    "image/jpg",
    "image/gif",
    "application/pdf",
    "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
    "application/vnd.ms-excel"
  ];
  const resetFile = (e) => {
    setFile(null)
    if(document.getElementById('upload-control')){
      let control = document.getElementById('upload-control')
      control.value = ""
    }
  }
  const handleUpload = (e) => {
    let selected = e.target.files[0];
    if (selected && types.includes(selected.type)) {
      if(!props.urls.find((urlObj) => {
        return urlObj.name === selected.name
      })){
        setFile(selected);
      } else {
        console.log('Already selected')
      }
    } else {
      setFile(null);
      setError("Please select an image, pdf, or word file (png, jpeg, jpg, docx)");
    }
  };

  return (
    <>
      <Form.Control
        // disabled={props.isDisabled}
        type="file"
        onChange={handleUpload}
      />
      {/* <Button onClick={handleUpload} className='bg-secondary mx-1'>UPLOAD</Button> */}
      <div className="output">
        {error && <div className="error">{error}</div>}
        {file && <div className="file">{file.name}</div>}
        {file && (
          <Progress resetFile={resetFile} setFileFn={setFile} urls={props.urls} setUrl={props.setUrl} file={file} />
        )}
      </div>
    </>
  );
}

export default Upload;

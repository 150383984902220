import React, { useState, useEffect } from "react";
import Table from "react-bootstrap/Table";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import Badge from "react-bootstrap/Badge";
import axios from "axios";
import { Spinner, Row, Col } from "react-bootstrap";
import AddEditEndorsement from "./AddEditEndorsement";
import Swal from "sweetalert2";

function EndorsementTable(props) {
  const { loadEndorsements, endorsements } = props;
  const [selectedEnd, setselectedEnd] = useState();
  const [editModal, setEditModal] = useState(false);

  const handleEdit = (p) => {
    setselectedEnd(p);
    setEditModal(true);
  };
  const handleDelete = () => {
    axios
      .delete(`/api/endorsement/${selectedEnd.e_id}`)
      .then((res) => {
        Swal.fire({
          title: "Endorsement Deleted.",
          timer: 1000,
          position: "top-end",
          showConfirmButton: false,
        });
        setEditModal(false);
        loadEndorsements();
      })
      .catch((err) => {
        Swal.fire({ title: "Error", text: `Message: ${err}` });
      });
  };

  return (
    <Table striped hover bordered responsive className="">
      <thead>
        <tr>
          <th>#</th>
          <th>Position</th>
          <th>Politician</th>
          <th className="">Link</th>
          <th className="">Edit</th>
        </tr>
      </thead>
      <tbody>
        {endorsements &&
          endorsements
            .sort((a, b) => b.e_id - a.e_id)
            .map((e) => {
              return (
                <tr key={e.e_id}>
                  <td>{e.e_id}</td>
                  <td>{e.position}</td>
                  <td>
                  <a href={`/politician/${e.politician_id}`}>
                    {e.fn + " " + e.ln}
                  </a>
                </td>

                  <td>
                    <a href={e.link}>{e.link}</a>
                  </td>

                  <td>
                    <Badge pill onClick={() => handleEdit(e)} className=" btn">
                      Edit
                    </Badge>
                  </td>
                </tr>
              );
            })}
      </tbody>
      <Modal
        show={editModal}
        onHide={() => setEditModal(false)}
        size="lg"
        centered
      >
        <Modal.Header closeButton>
          <h4>Edit Post</h4>
        </Modal.Header>
          <AddEditEndorsement
            loadEndorsements={loadEndorsements}
            setEditModal={setEditModal}
            thisEnd={selectedEnd}
            editMode={true}
          />
        <Modal.Footer className="d-flex justify-content-between">
          <Button variant="secondary" onClick={() => handleDelete()}>
            Delete
          </Button>
          <Button variant="danger" onClick={() => setEditModal(false)}>
            Cancel
          </Button>
        </Modal.Footer>
      </Modal>
    </Table>
  );
}

export default EndorsementTable;

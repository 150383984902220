import axios from 'axios'
import React, { useState } from 'react'
import { ListGroup } from 'react-bootstrap'

function UserNote({note, fetchUserNotes}) {
const [editNote, setEditNote] = useState(false)
const [editNoteText, setEditNoteText] = useState(note.note_text)
const submitEdit = () => {
    axios.put(`/api/note/${note.note_id}`, { note: editNoteText })
    .then(() => {
        setEditNote(false)
        fetchUserNotes()
    })
    .catch((error) => console.error(error))
}
  return (
    <ListGroup.Item
                key={note.note_id}
                onClick={() => setEditNote(true)}
              >
                {editNote ? (
                  <>
                    <input
                      type="text"
                      value={editNoteText}
                      onChange={(e) => setEditNoteText(e.target.value)}
                    />
                    <button onClick={() => submitEdit()}>
                      Save
                    </button>
                  </>
                ) : (
                  <p>{note.note_text}</p>
                )}
                </ListGroup.Item>
  )
  
}

export default UserNote
import React from "react";

function DonateButton() {
  return (
    <form action="https://www.paypal.com/donate" method="post" target="_top">
      <input type="hidden" name="hosted_button_id" value="H27QN7R7XBRKU" />
      <button
        id="donate-button-link"
        className="header-links"
        type="submit"
        border="0"
        name="submit"
        title="Donate with PayPal or a Debit/Credit Card"
      >
        Donate
      </button>
      <img
        alt=""
        border="0"
        src="https://www.paypal.com/en_US/i/scr/pixel.gif"
        width="1"
        height="1"
      />
    </form>
  );
}

export default DonateButton;

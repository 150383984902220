import axios from "axios";
import React, { useState, useEffect } from "react";
import { Container, ListGroup, Navbar} from "react-bootstrap";
import ComingSoon from "../ComingSoon/ComingSoon";
import TwoTwoFive from "../TwoTwoFive/TwoTwoFive";

function GetInvolved() {
  const [links, setLinks] = useState();
  const [loaded, setLoaded] = useState(false);
  useEffect(() => {
    loadData();
  }, []);
  const loadData = () => {
    setLoaded(false);
    axios
      .get("/api/join/links")
      .then((res) => {
        // setLinks(res.data);
        sortLinks(res.data)
        setLoaded(true);
      })
      .catch((err) => console.log(err));
  };
  const sortLinks = (l) => {
    let sorted = l.sort((a,b) =>{
      
     return a.city_name.localeCompare(b.city_name)
      
    })
     
    setLinks(sorted)
  }
  return (
    <Container>
      <TwoTwoFive />
      <hr />
        <Container className='text-center my-5'>
            <h3 className='text-danger'>Join a local group today!</h3>
            <h6>We want to hear your voice and what you have to offer, join one of these groups by following the links.</h6>

        </Container>
      <ListGroup>
          {loaded && links && links.map((l,i) => {
              return (
                  <ListGroup.Item key={l.link_id}>
                     <a target="_blank" href={l.link_url} className='text-primary m-0 p-0'> <h6  className='text-primary m-0 p-0'>{l.title}</h6></a>
                     <p className='text-dark p-0 m-0'>{l.description}</p>
                  </ListGroup.Item>

              )
          })}
          
      </ListGroup>
    </Container>
  );
}

export default GetInvolved;

import React, { useState, useEffect } from "react";

import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import dateFormat from "dateformat";
import { Badge, ListGroup, ProgressBar } from "react-bootstrap";
import WallOfFame from "../Walls/WallOfFame";
import WallOfShame from "../Walls/WallOfShame";
import Endorsement from "../Endorsements/Endorsement";
function ViewPol(props) {
  const [fame, setFame] = useState();
  const [shame, setShame] = useState();
  const { pol } = props;
  useEffect(() => {
    fameShameFilter(pol.posts);
  }, []);
  const fameShameFilter = (unfiltered) => {
    let shame = unfiltered.filter((p) => p.wall_type === "shame");
    let fame = unfiltered.filter((p) => p.wall_type === "fame");
    setShame(shame);
    setFame(fame);
  };
  const WallBar = () => {
    let famePercentage;
    let shamePercentage;
    let fameCount = pol.posts.filter((p) => p.wall_type === "fame").length;
    let shameCount = pol.posts.length - fameCount;
    famePercentage = (fameCount / pol.posts.length) * 100;
    shamePercentage = (shameCount / pol.posts.length) * 100;
    console.log(
      "perc1",
      fameCount,
      shameCount,
      famePercentage,
      shamePercentage
    );

    if (shameCount === 0 && fameCount > 0) {
      famePercentage = 100;
    }
    if (shameCount > 0 && fameCount === 0) {
      shamePercentage = 100;
    }
    console.log("perc", famePercentage, 1 - famePercentage);
    return (
      <ProgressBar className='mb-5'>
        <ProgressBar
        striped
        animated
          variant="primary"
          label={`Fame: ${famePercentage}%`}
          now={famePercentage}
        />
        {shamePercentage > 0 ? (
          <ProgressBar
          animated
          striped
            variant="danger"
            label={`Shame: ${shamePercentage}%`}
            now={shamePercentage}
          />
        ) : null}
      </ProgressBar>
    );
  };
  return (
    <Container>
      <Row className="d-flex justify-content-center">
        <Col xs="12" sm="8" md="6" lg="3">
          <img className="w-100" src={pol.img_url} alt={pol.fn + ' ' + pol.ln} />
        </Col>
        <Col>
          <Container>
            <h1><u>{pol.fn + " " + pol.ln}</u></h1>
            <h5>{pol.noun}</h5>
            {pol.positions.length > 0 ? (
              <>
                <h4 className='mt-5'>Positions Held:</h4>
                <ListGroup>
                  {pol.positions &&
                    pol.positions.map((pos) => {
                      return (
                        <ListGroup.Item className="d-flex justify-content-between">
                          <p className="m-0 p-0">{pos.position}</p>
                          <p className="m-0 p-0">
                            Start Date:{" "}
                            {dateFormat(pos.start_date, "paddedShortDate")}
                          </p>
                          {pos.current ? (
                            <Badge bg="success">Current</Badge>
                          ) : (
                            <p className="m-0 p-0">
                              End Date:{" "}
                              {dateFormat(pos.end_date, "paddedShortDate")}
                            </p>
                          )}
                        </ListGroup.Item>
                      );
                    })}
                </ListGroup>
              </>
            ) : null}
          </Container>
          <Container>
            
            {pol.endorsements.length > 0 ? (
              <>
                <h4 className='mt-5'>Endorsements:</h4>
                <ListGroup>
                  {pol.endorsements &&
                    pol.endorsements.map((e) => {
                      return (
                        <ListGroup.Item >
                         <Endorsement  endorsement={e} />
                        </ListGroup.Item>
                      );
                    })}
                </ListGroup>
              </>
            ) : null}
          </Container>
        </Col>
      </Row>
      <Row className='mt-4 border rounded-3 p-2'>
        <Col  xs="12">
          <h2>Wall Posts</h2>
          <h6><u>Ratio:</u></h6>
          <WallBar />
        </Col>
        <Col xs="12" md="6">
          <WallOfFame fame={fame} />
        </Col>
        <Col xs="12" md="6">
          <WallOfShame shame={shame} />
        </Col>
      </Row>
      <Row className='mt-5'>
        
      </Row>
    </Container>
  );
}

export default ViewPol;

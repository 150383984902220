
import './App.scss';
import Container from 'react-bootstrap/Container';
import routes from './routes'
import Header from './Components/Header/Header';
import './firebase.js'

function App(props) {


  return (
    <Container fluid='xxl' className='App p-0 '>
      <Header />
      {routes}
    </Container>
  );
}

export default App;

import axios from 'axios';
import React from 'react'
import { Button, Col, Row } from 'react-bootstrap'
import Swal from 'sweetalert2';
function ChangeRole({user_id, loadUsers}) {
    const changeRole = (role) => {
        axios
          .put("/api/auth/user/role", { role, user_id})
          .then((res) => {
            Swal.fire({ icon: "success", title: "Role Updated", timer: 2000 });
    
            loadUsers();
          });
      };
  return (
    <Row
    fluid
    className="d-flex flex-column border rounded-3 border-secondary p-3 mt-3"
  >
    <Col>
      <Button
        variant="warning"
        className="btn-sm my-2"
        onClick={() => changeRole("user")}
      >
        Make "User"
      </Button>
    </Col>
    <Col>
      <Button
        variant="primary"
        className="btn-sm my-2"
        onClick={() => changeRole("member")}
      >
        Make "Member"
      </Button>
    </Col>
    <Col>
      <Button
        variant="danger"
        className="btn-sm my-2"
        onClick={() => changeRole("admin")}
      >
        Make "Admin"
      </Button>
    </Col>
    <Col>
      <Button
        variant="dark"
        className="btn-sm my-2"
        onClick={() => changeRole("not interested")}
      >
        Make "Not Interested"
      </Button>
    </Col>
    <Col>
      <Button
        variant="secondary"
        className="btn-sm my-2"
        onClick={() => changeRole("inactive")}
      >
        Make "Inactive"
      </Button>
    </Col>
  </Row>
  )
}

export default ChangeRole
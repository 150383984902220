import React, {useEffect} from "react";
import useStorage from "../../hooks/useStorage";
import ProgressBar from "react-bootstrap/ProgressBar";



const Progress = ({ file, setFileFn,setUrl,urls,resetFile }) => {
 


  const { url, progress } = useStorage(file);
  useEffect(() => {
    if (url) {
      setUrl([{name:file.name, url: url},...urls ])
      resetFile()  

    }
    // eslint-disable-next-line
  }, [url]);
  return (
    <ProgressBar animated now={progress}  />
  );
};

export default Progress;

import React, { useState } from "react";
import Table from "react-bootstrap/Table";
import Badge from "react-bootstrap/Badge";
import Button from "react-bootstrap/Button";
import Spinner from "react-bootstrap/Spinner";
import Modal from "react-bootstrap/Modal";
import AddNewPolitician from "./AddNewPolitician";
import ViewPol from "../../../Politician/ViewPol";
import axios from "axios";
import Swal from "sweetalert2";
function PoliticianTable(props) {
  const [selectedPol, setSelectedPol] = useState();
  const [deleting, setDeleting] = useState(false);
  const [viewModal, setViewModal] = useState(false);
  const [editModal, setEditModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const handleOptions = (p, option) => {
    setSelectedPol(p);
    switch (option) {
      case "view":
        setViewModal(true);
        break;
      case "edit":
        setEditModal(true);
        break;
      case "delete":
        setDeleteModal(true);
        break;
    }
  };
  const handleDelete = (politician_id) => {
    Swal.fire({icon: "warning",
    title: "Are you sure?",
    text: 'This will delete the politician and all related wall posts and endorsements.',
    showCancelButton: true,
    confirmButtonText: `Yes, delete ${selectedPol.fn + ' ' + selectedPol.ln}`,
    confirmButtonColor: 'red',
    cancelButtonColor: 'blue'
}).then(result => {
  if(result.isConfirmed){
    setDeleting(true);
    axios.delete(`/api/admin/politician/${politician_id}`).then((res) => {
      props.loadInfo();
      Swal.fire({icon: "success", title: "Deleted.", timer: 1000, showConfirmButton: false})
      setEditModal(false)
      setDeleting(false);
    });

  } 
    
  
})
  };
  return (
    <Table striped hover bordered className="">
      <thead>
        <tr>
          <th>Name</th>
          <th>Party</th>
          <th className="text-center">Options</th>
        </tr>
      </thead>
      <tbody>
        {props.politicians &&
          props.politicians.map((p) => {
            return (
              <tr key={p.politician_id}>
                <td>
                  <a href={`/politician/${p.politician_id}`}>
                    {p.fn + " " + p.ln}
                  </a>
                </td>
                <td>{p.party}</td>
                <td className="d-flex justify-content-around align-items-center">
                  {/* <Badge pill  bg="success" onClick={() => handleOptions(p,'view')} className='btn m-1'>View</Badge> */}
                  <Badge
                    pill
                    onClick={() => handleOptions(p, "edit")}
                    className=" btn m-1"
                  >
                    Edit
                  </Badge>
                  {/* <Badge pill onClick={() => handleOptions(p,'delete')} bg="danger" className='btn m-1'>Delete</Badge> */}
                </td>
              </tr>
            );
          })}
      </tbody>
      <Modal
        show={editModal}
        onHide={() => setEditModal(false)}
        size="lg"
        centered
      >
        <Modal.Header closeButton>
          <h4>{selectedPol && selectedPol.fn + " " + selectedPol.ln}</h4>
        </Modal.Header>
        <AddNewPolitician
          loadInfo={props.loadInfo}
          setEditModal={setEditModal}
          editMode={true}
          pol={selectedPol}
          parties={props.info.parties}
          positions={props.info.positions}
        />
        <Modal.Footer className="d-flex justify-content-between">
          <Button
            variant="secondary"
            className="float-start"
            disabled={deleting}
            onClick={() => handleDelete(selectedPol.politician_id)}
          >
            Delete
          </Button>
          <Button className="float-end" 
          variant='danger'
          disabled={deleting}
          onClick={() => setEditModal(false)}>
            Cancel
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal
        show={viewModal}
        onHide={() => setViewModal(false)}
        size="lg"
        centered
      >
        <Modal.Header closeButton>
          <h4>{selectedPol && selectedPol.fn + " " + selectedPol.ln}</h4>
        </Modal.Header>
        <ViewPol
          setEditModal={setViewModal}
          info={props.info}
          pol={selectedPol}
          parties={props.info.parties}
          positions={props.info.positions}
        />
      </Modal>

      
    </Table>
  );
}

export default PoliticianTable;

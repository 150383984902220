import React, { useEffect, useState } from "react";
import Container from "react-bootstrap/Container";
import Badge from "react-bootstrap/Badge";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Collapse from "react-bootstrap/Collapse";
import { Form, ListGroup, Spinner } from "react-bootstrap";
import axios from "axios";
import { phoneFormat } from "../../../../commonFunctions";
import UserDetails from "./UserDetails";
import { set } from "prerender-node";

let sorting = false;
function UserManager() {
  const [users, setUsers] = useState([]);
  const [sortedUsers, setSortedUsers] = useState([]);
  const [loaded, setLoaded] = useState(false);
  const [selectedUserId, setSelectedUserId] = useState(0);
  const [showInactive, setShowInactive] = useState(false);
  const [showNotInterested, setShowNotInterested] = useState(false);
  useEffect(() => {
    loadData();
  }, []);
  const loadData = () => {
    setLoaded(false);
    axios.get("/api/admin/users").then((res) => {
      setUsers(res.data);
      setSortedUsers(res.data.filter((u) => u.role !== "inactive"));
      setLoaded(true);
    });
  };
  useEffect(() => {
    let tempUsers = [...users];
    if (showInactive) {
      // setSortedUsers(tempUsers)
      if (showNotInterested) {
        setSortedUsers(tempUsers);
      }
      else {
        setSortedUsers(tempUsers.filter((u) => u.role !== "not interested"));
      }
      // handleSort({target: {value: ""}})
    } else if (showNotInterested) {
      setSortedUsers(tempUsers.filter((u) => u.role !== "inactive"));
    } else {
      setSortedUsers(
        tempUsers.filter((u) => {
          return u.role !== "inactive" && u.role !== "not interested";
        })
      );
    }

  }, [showInactive, showNotInterested, users]);
  const roleColors = {
    admin: "danger",
    member: "primary",
    user: "warning",
    inactive: "secondary",
    notinterested: "dark",
  };
  const handleSelect = (id) => {
    if (selectedUserId === id) {
      setSelectedUserId(0);
      return;
    }
    setSelectedUserId(id);
  };
  console.log("users", users);
  const handleSort = (e) => {
    sorting = true;
    const sortBy = e.target.value;
    let tempSortedUsers = [...sortedUsers];

    if (sortBy === "precinct") {
      tempSortedUsers.sort((a, b) => {
        return a.caucus_precinct?.localeCompare(b.caucus_precinct) || 0;
      });
    } else if (sortBy === "fn") {
      tempSortedUsers.sort((a, b) => a?.fn.localeCompare(b.fn));
    } else if (sortBy === "ln") {
      tempSortedUsers.sort((a, b) => a?.ln.localeCompare(b.ln));
    } else if (sortBy === "role") {
      tempSortedUsers.sort((a, b) => a.role.localeCompare(b.role));
    } else if (sortBy === "city") {
      tempSortedUsers.sort((a, b) => a.city?.localeCompare(b.city) || 0);
    } else if (sortBy === "county") {
      tempSortedUsers.sort((a, b) => a.county?.localeCompare(b.county) || 0);
    } else if (sortBy === "") {
      tempSortedUsers.sort((a, b) => a.user_id - b.user_id);
    }

    setSortedUsers(tempSortedUsers);
    sorting = false;
  };
  return (
    <Container>
      <Row className="my-3">
        <Col xs="auto">
          <Form.Select onChange={handleSort}>
            <option value="">Sort By</option>
            <option value="city">City</option>
            <option value="county">County</option>
            <option value="precinct">Precinct</option>
            <option value="fn">First Name</option>
            <option value="ln">Last Name</option>
            <option value="role">Role</option>
          </Form.Select>
        </Col>
      </Row>
      <Row className="mb-3">
        <Col>
          <Form.Check
            type="switch"
            id="showInactive"
            label="Show Inactive Users"
            checked={showInactive}
            onChange={() => setShowInactive(!showInactive)}
          />
        </Col>
        <Col>
          <Form.Check
            type="switch"
            id="showNotInterested"
            label="Show Not Interested Users"
            checked={showNotInterested}
            onChange={() => setShowNotInterested(!showNotInterested)}
          />
        </Col>
      </Row>
      <ListGroup>
        <ListGroup.Item className="">
          <Row>
            <Col xs="6" md="6" lg="4">
              <strong>Name </strong>
            </Col>
            <Col xs="6" md="6" lg="2">
              {" "}
              <strong>Role</strong>
            </Col>
            <Col>
              <strong>Precinct</strong>
            </Col>
            <Col>
              <strong>City</strong>
            </Col>
            <Col>
              <strong>County</strong>
            </Col>
          </Row>
        </ListGroup.Item>
        {loaded && !sorting && users ? (
          sortedUsers.map((u) => {
            return (
              <ListGroup.Item
                key={u.user_id}
                className={
                  selectedUserId === u.user_id
                    ? "border-selected pointer"
                    : "pointer"
                }
              >
                <Row onClick={() => handleSelect(u.user_id)}>
                  <Col xs="6" md="6" lg="4">
                    <strong className="" style={{ fontSize: "15pt" }}>
                      {u.display_name}{" "}
                    </strong>
                  </Col>
                  <Col xs="6" md="6" lg="2">
                    {" "}
                    <Badge
                      className="float-end"
                      bg={roleColors[u.role.replace(" ", "")]}
                    >
                      {u.role.toUpperCase()}{" "}
                    </Badge>
                  </Col>
                  <Col>
                    {u.caucus_precinct ? (
                      <span>{u.caucus_precinct} </span>
                    ) : (
                      <></>
                    )}
                  </Col>
                  <Col>{u.city ? <span>{u.city} </span> : <></>}</Col>
                  <Col>{u.county ? <span>{u.county} </span> : <></>}</Col>
                </Row>
                <div>
                  {selectedUserId === u.user_id && (
                    <UserDetails user={u} loadUsers={loadData} />
                  )}
                </div>
              </ListGroup.Item>
            );
          })
        ) : (
          <Container className="main-content d-flex justify-content-center">
            <Spinner animation="grow" />
          </Container>
        )}
      </ListGroup>
    </Container>
  );
}

export default UserManager;

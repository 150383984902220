import React, { useState, useEffect } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Spinner from "react-bootstrap/Spinner";
import AddNewPolitician from "./AddNewPolitician";
import AddParty from "./AddParty";
import AddPosition from "./AddPosition";
import AddHeldPosition from "./AddHeldPosition";
import axios from "axios";
import PoliticianTable from "./PoliticianTable";


function PoliticianManager(props) {
  const [info, setInfo] = useState();
  const [loaded, setLoaded] = useState(false);
  useEffect(() => {
    loadInfo()
  }, []);
  const loadInfo = () => {
    axios
    .get("/api/admin/politician")
    .then((res) => {
      setInfo(res.data);
    })
    .catch((err) => console.log(err));
  }
  useEffect(() => {
     
    if (info) {
      setLoaded(true);
    }
  }, [info]);
  return loaded ? (
    <Container className=''>
      <Row  >
        <Col xs="12" lg="6" >
          <AddNewPolitician loadInfo={loadInfo} parties={info.parties} positions={info.positions} />
        </Col>
        <Col xs="12" lg="6">
          <AddParty />
        </Col>
      </Row>
      <Row>
        <Col xs="12" lg="6">
          <AddPosition />
        </Col>
        <Col xs="12" lg="6">
          <AddHeldPosition
            positions={info.positions}
            politicians={info.politicians}
          />
        </Col>
      </Row>
      <Row>
        <Col xs='12' lg='6' >
          <h3>Politicians</h3>
          <PoliticianTable politicians={info.politicians} loadInfo={loadInfo} info={info}/>
        </Col>
      </Row>
    </Container>
  ) : (
    <Row>
      <Spinner
        animation="grow"
        variant="secondary"
        className="mx-auto mt-5"
        style={{ height: "200px", width: "200px" }}
      />
    </Row>
  );
}

export default PoliticianManager;

import React, { useState, useEffect } from "react";
import "./Header.scss";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import Row from "react-bootstrap/Row";
import { Link } from "react-router-dom";
import axios from "axios";
import { withRouter } from "react-router-dom";
import { Modal } from "react-bootstrap";
import SignInForm from "../SignIn/SignInForm";
import { getAuth, signOut } from "firebase/auth";
import DonateButton from "../Donate/DonateButton";
function Header(props) {
  let auth = getAuth();
  const [signInModal, setSignInModal] = useState(false);
  const [loggedIn, setLoggedIn] = useState(false);
  const [isAdmin, setIsAdmin] = useState(false);
  useEffect(() => {
    checkUser();
  }, [props.history.location.pathname]);
  const checkUser = () => {
    axios
      .get("/api/auth/user")
      .then((res) => {
        if (res.data.user_id) {
          setLoggedIn(true);
          if (res.data.role === "admin") {
            setIsAdmin(true);
          }
        } else {
          setLoggedIn(false);
        }
      })
      .catch(() => setLoggedIn(false));
  };
  const routeToPortal = () => {
    if (isAdmin) {
      props.history.push("/admin");
    } else {
      props.history.push("/portal");
    }
  };
  const handleSignOut = () => {
    axios.post("/api/auth/logout").then(() => checkUser());
    signOut(auth)
      .then((res) => {
        // console.log('logged out', res.data)
        props.history.push("/");
      })
      .catch((err) => console.log(err));
  };
  return (
    // <Row>
    <>
      <Navbar
        className="header m-0  p-0 "
        bg="light"
        expand="xl"
        collapseOnSelect="true"
      >
        <Container className="d-flex justify-content-between">
          <Navbar.Brand
            href="/"
            id="brand"
            className="brand d-flex align-items-center"
          >
            <img
              className=" align-content-center  me-2 header-logo"
              id="logo-image"
              src="/flag-logo.png"
              alt="USA flag logo"
            />

            <Navbar.Text
              id="title-text"
              className="title-text align-middle text-wrap text-center "
            >
              Utah Conservatives
            </Navbar.Text>
          </Navbar.Brand>

          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse>
            <Nav className="d-flex justify-content-end align-items-center w-100">
              <DonateButton />

              <Nav.Link href="/walls">
                <span className="header-links text-primary">Wall Posts</span>
              </Nav.Link>
              <Nav.Link href="/endorsements">
                <span className="header-links text-primary">Endorsements</span>
              </Nav.Link>
              <Nav.Link href="/join">
                <span className="header-links text-primary">Get Involved</span>
              </Nav.Link>

              <Nav.Link href="/contact">
                <span className="header-links text-primary">Contact Us</span>
              </Nav.Link>
              {loggedIn ? (
                <>
                  <div onClick={() => routeToPortal()}>
                    <span className="header-links text-primary pointer underline">
                      Portal
                    </span>
                  </div>
                  <Nav.Link onClick={() => handleSignOut()} href={`/`}>
                    <span className="header-links text-primary">Sign Out</span>
                  </Nav.Link>
                </>
              ) : (
                // <Nav.Link

                //   href={`/${props.location.pathname}`}
                // >
                <>
                  <Nav.Link onClick={() => {}} href={`/signup`}>
                    <b className="header-links text-primary">Sign Up</b>
                  </Nav.Link>
                  <span
                    onClick={() => setSignInModal(true)}
                    className="header-links pointer text-primary"
                  >
                    Sign In
                  </span>
                </>
                // </Nav.Link>
              )}
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
      <Modal show={signInModal} onHide={() => setSignInModal(false)}>
        <Modal.Body>
          <SignInForm setSignInModal={setSignInModal} />
        </Modal.Body>
      </Modal>
    </>
    // </Row>
  );
}

export default withRouter(Header);

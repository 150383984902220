import React, { useEffect, useState } from "react";
import Table from "react-bootstrap/Table";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import { phoneFormat } from "../../../../commonFunctions";
import axios from "axios";
import Swal from "sweetalert2";
import UserTasks from "./UserTasks";
import ChangeRole from "./ChangeRole";
import { Badge, Form } from "react-bootstrap";
import UserNotes from "./UserNotes";
function UserDetails({ user, loadUsers }) {
  const [interests, setInterests] = useState("");
  const [showChangeRole, setShowChangeRole] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [editedUser, setEditedUser] = useState({ ...user });

  const handleEdits = async () => {
    try {
      await axios.put(`/api/user/${user.user_id}`, editedUser);
      setEditMode(false);
      Swal.fire({ icon: "success", title: "User Updated", timer: 2000 });
      loadUsers();
    } catch (error) {
      console.error(error);
    }
  };
  const handleChange = (e) => {
    setEditedUser({ ...editedUser, [e.target.name]: e.target.value });
  };
  useEffect(() => {
    let tempInterests = "";
    if (user.interests) {
      console.log("user.interests", user.interests);
      let parsed = JSON.parse(user?.interests) || [];
      let cap = parsed.map((i) => i.charAt(0).toUpperCase() + i.slice(1));
      tempInterests = cap.join(", ");
      setInterests(tempInterests);
    } else {
      setInterests("");
    }
  }, []);
  return (
    <div className="my-4">
      <Table size="sm" striped="columns" bordered hover>
        <tr>
          <td colSpan="2">
            {editMode ? (
              <button className="float-end btn bg-light" onClick={handleEdits}>
                <strong className="text-primary">Save</strong>
              </button>
            ) : (
              <button
                className="float-end btn bg-light"
                onClick={() => setEditMode(true)}
                variant="warning"
              >
                <strong className="text-primary ">Edit</strong>
              </button>
            )}
          </td>
        </tr>
        <tr>
          <th>Phone</th>
          <td>
            {editMode ? (
              <Form.Control
                type="text"
                name="phone"
                value={editedUser.phone}
                onChange={handleChange}
              />
            ) : (
              phoneFormat(user.phone)
            )}
          </td>
        </tr>
        <tr>
          <th>Email</th>
          <td>
            {editMode ? (
              <Form.Control
                type="text"
                name="email"
                value={editedUser.email}
                onChange={handleChange}
              />
            ) : (
              user.email
            )}
          </td>
        </tr>
        <tr>
          <th>Street</th>
          <td>
            {editMode ? (
              <Form.Control
                type="text"
                name="street"
                value={editedUser.street}
                onChange={handleChange}
              />
            ) : (
              user.street
            )}
          </td>
        </tr>
        <tr>
          <th>City</th>
          <td>
            {editMode ? (
              <Form.Control
                type="text"
                name="city"
                value={editedUser.city}
                onChange={handleChange}
              />
            ) : (
              user?.city?.toUpperCase()
            )}
          </td>
        </tr>
        <tr>
          <th>State, Zipcode</th>
          <td>
            {user.state?.toUpperCase()}
            {editMode ? (
              <Form.Control
                type="text"
                name="zip"
                value={editedUser.zip}
                onChange={handleChange}
              />
            ) : (
              user.zip
            )}
          </td>
        </tr>
      </Table>
      <Button
        className="btn-sm my-2"
        variant={showChangeRole ? "danger" : "primary"}
        onClick={() => setShowChangeRole(!showChangeRole)}
      >
        {showChangeRole ? "Cancel Change Role" : "Change Role"}
      </Button>
      {showChangeRole && (
        <ChangeRole user_id={user.user_id} loadUsers={loadUsers} />
      )}
      <Row>
        <Col>
          Service interests:{" "}
          <Badge>{interests.length > 0 ? interests : "None selected."}</Badge>
        </Col>
      </Row>
      <hr />
      <UserNotes user_id={user.user_id} />
      <hr />
      <Row>
        <Col>
          <UserTasks user_id={user.user_id} />
        </Col>
      </Row>
    
    </div>
  );
}

export default UserDetails;

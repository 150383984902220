import React, { useEffect, useState } from "react";
import "./Landing.scss";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import axios from "axios";
import { Navbar } from "react-bootstrap";
import WallOfShame from "../Walls/WallOfShame";
import WallOfFame from "../Walls/WallOfFame";
function Landing() {
  const [posts, setPosts] = useState();
  const [fame, setFame] = useState();
  const [shame, setShame] = useState();
  const [loaded, setLoaded] = useState();
  useEffect(() => {
    getAllPosts();
  }, []);

  const getAllPosts = () => {
    axios.get("/api/posts").then((res) => {
       
      let shame = res.data.filter((p) => p.wall_type === "shame").sort((a,b) => new Date(b.event_date) - new Date(a.event_date));
      let fame = res.data.filter((p) => p.wall_type === "fame").sort((a,b) => new Date(b.event_date) - new Date(a.event_date));
      setShame(shame);
      setFame(fame);
      setPosts(res.data);
      setLoaded(true);
    });
  };

  
  return (
    <Container fluid className="landing-container">
      <Row className='intro-row'>
       
        <Col xs='12' md='6'>
          <Container className="intro-col bg-light rounded-3 my-2 p-3 text-center">
            <h4>Protecting the Constitution</h4>
            <p className="">
              We believe that America is great due to the inspired Constitution
              as well as the blessings of God. The Constitution is being
              threatened on many fronts.
              <br /> <br /> The Constitution protects the freedoms of <b>all</b> of our citizens regardless of political party, race, religion, etc. It is the duty of citizens to unite to support Constitutional government and conservative principles.{" "}
              <br /> <br /> We are a group focused on{" "}
              <strong> education </strong>and <strong> action</strong>. Find a
              local <a  href="/join">Facebook group</a> to get involved.{" "}
            </p>
          </Container>
        </Col>
      </Row>
      <Navbar>
        <h3 className="text-center w-100">Holding Our Elected Accountable</h3>
        <a href="/walls" className='text-primary text-center mb-2'><Button variant='success'>Search</Button></a>
      </Navbar>
      <Row>
      </Row>
      <Row>
        <Col xs="12" md="6" >
          <WallOfFame fame={fame} />
        </Col>
        <Col xs="12" md="6" >
          <WallOfShame shame={shame} />
        </Col>
      </Row>
    </Container>
  );
}

export default Landing;

import React, { useState } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Modal from "react-bootstrap/Modal";
import dateFormat from "dateformat";
import Col from "react-bootstrap/Col";
import "./Post.scss";

function Post(props) {
  const [cardClicked, setCardClicked] = useState(false);
  const [fileModal, setFileModal] = useState({ show: false, isDoc: false });

  const { post } = props;
  const viewFile = () => {
    let isDoc = false;
    if (
      post.file_url.includes(".doc") ||
      post.file_url.includes(".docx")
    ) {
      isDoc = true;
    }
    setFileModal({ show: true, isDoc });
  };
  // console.log('props',props)
  return (
    <Container className="border bg-light rounded-3 my-2 p-2 shadow post-container">
      <Row>
        <Col className="post-col d-flex flex-column align-items-center">
          {post.post_img.length > 0 ? (
            <img className="post-img " src={post.post_img} loading='lazy' alt="Post" />
          ) : (
            <img className="post-img" src={post && post.img_url} loading='lazy' alt="" />
          )}
          <h5 className="mt-1">
            <a href={`/politician/${post.politician_id}`}>
              {post.fn + " " + post.ln}
            </a>
          </h5>
        </Col>
        <Col
          xs="12"
          md="6"
          className="d-flex flex-column justify-content-between"
        >
          <div className="title-line w-100 d-flex flex-column justify-content-between">
            <strong>{post.title}</strong>

            <p
              onClick={() => {
                setCardClicked(true);
              }}
              className={`body-text${cardClicked ? "-clicked" : ""}`}
            >
              {post.body_text}{ " "}
              
            </p>
            {post.file_url && (
                <p
                  className="pointer m-0 p-0"
                  onClick={() => {
                    viewFile();
                  }}
                >
                  <u>View attached file.</u>
                </p>
              )}
          </div>
          <div className="w-100 d-flex justify-content-between align-items-end">
            {post.party ? (
              <img
                className="party-logo "
                src={
                  post.party.toLowerCase() === "republican"
                    ? "https://upload.wikimedia.org/wikipedia/commons/thumb/9/9b/Republicanlogo.svg/1179px-Republicanlogo.svg.png"
                    : post.party.toLowerCase() === "democratic"? "https://upload.wikimedia.org/wikipedia/commons/thumb/0/02/DemocraticLogo.svg/1200px-DemocraticLogo.svg.png" : post.party.toLowerCase() === "independent" ? "https://upload.wikimedia.org/wikipedia/en/a/a3/IPNY_Logo_3423432.png" : null
                }
                loading="lazy"
                alt=""
              />
            ) : null}
            {post.event_date !== null ? (
              <p className='p-0 m-0 '>{dateFormat(post.event_date, 'UTC:mm/dd/yyyy')}   </p>
            ) : null}
            {post.link.length > 0 ? (
              <a target="_blank" href={post.link} rel="noreferrer">
                See article.
              </a>
            ) : null}
               
              
          </div>
        </Col>
      </Row>
      <Modal
        show={fileModal.show}
        onHide={() => setFileModal({ show: false, isDoc: false })}
        fullscreen
        centered
      >
        <Modal.Header closeButton>
          <h4>
            {post.file_name}{" "}
            <a href={post.file_url} target="_blank" rel="noreferrer">
              {fileModal.isDoc ? "Download" : "Open in a new tab."}
            </a>
          </h4>
        </Modal.Header>
        <Modal.Body>
          <iframe
            id="file-iframe"
            title={post.fn + post.ln}
            src={fileModal.isDoc ? `https://docs.google.com/gview?url=${post.file_url}&embedded=true` : `${post.file_url}#toolbar=0&navpanes=0&scrollbar=0`}
          />
          
        </Modal.Body>
      
      </Modal>
    </Container>
  );
}

export default Post;
